/* account setup  */

.ac-setup #v-pills-BookingLink .left-content,
.ac-setup #v-pills-SMSUsage .left-content {
  padding: 35px 40px;
}

.ac-setup #v-pills-BookingLink .right-content,
.ac-setup #v-pills-SMSUsage .right-content {
  padding: 40px;
}

.ac-setup #v-pills-BookingLink,
.ac-setup #v-pills-SMSUsage {
  background-color: #f9f6f4;
}

.ac-setup .round-icon {
  min-width: 35px;
  min-height: 35px;
  background-color: #bb5876;
  border-radius: 100%;
}

.color-default {
  color: #3a3432 !important;
}

.pest-link {
  background-color: #f9f6f4;
  border-radius: 5px;
  padding: 8px 14px;
}

.btnlight-pink.btn {
  background-color: #f4eeeb;
  border-color: #f4eeeb;
  color: #3a3432;
}

.disable span {
  color: #9ca3af !important;
}

.cursor-poniter {
  cursor: pointer;
}

.border-left {
  border-left: 1px solid #eee;
}

.border-bottom {
  border-bottom: 1px solid #eee;
}

.border-right {
  border-right: 1px solid #eee;
}

textarea.textarea-lg {
  min-height: 176px;
}

.f-18 {
  font-size: 18px !important;
}

.mobile-data {
  max-width: 295px;
  margin: 0 auto;
  padding-top: 520px;
}

.mobile-data .mobile-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.mobile-data .mobile-txt {
  top: 80px;
  background-color: #8c1c4d;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 11px;
  position: absolute;
  margin: 0 26px;
  min-width: 245px;
  min-height: 245px;
  overflow-y: auto;
  max-width: 245px;
  max-height: 300px;
  overflow-wrap: break-word;
}
.mobile-data .mobile-txt a {
  color: #fff;
}

.canvatemplate-drawer .container .container {
  position: relative;
  text-align: center;
  color: white;
  z-index: 1;
}

.canvatemplate-drawer .bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.canvatemplate-drawer .top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.canvatemplate-drawer .top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

.canvatemplate-drawer .bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.canvatemplate-drawer .centered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.birthday-mobile-right-col {
  padding: 22px 23px 26px 41px;
  height: 100%;
}
.marketing-mobile-birthday {
  max-width: 411px;
  margin: 0 auto;
}
.mobile-offer-wrapper {
  position: relative;
}
.markerting-mob-img {
  max-width: 411px;
  height: 752px;
  width: 100%;
}
.brithday-mail-smstext {
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 310px;
  margin: 0 auto;
  top: 108px;
  overflow-y: scroll;
  max-height: 608px;
  padding: 18px 19px;
  min-height: 200px;
  background: #8c1c4d;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 11px;
  overflow-wrap: break-word;
}
.marketing-sms-text {
  position: absolute;
  background: #00c4cc;
  width: 224px;
  height: 224px;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}
.pre-appon-inner-tetx {
  position: absolute;
  bottom: 83px;
  width: 100%;
  max-width: 258px;
  margin: 0 auto;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.pre-appon-inner-tetx p {
  font-size: 16px;
  line-height: 29px;
}
.btn-mail {
  background: #999999;
  color: #fff;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 20px;
  display: inline-block;
  transition: all 0.4s;
}
.brithday-mail-img-text {
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 310px;
  margin: 0 auto;
  top: 108px;
  overflow-y: scroll;
  max-height: 608px;
  padding: 18px 19px;
  min-height: 345px;
  border-radius: 11px;
}
.brithday-mail-img-text .mail-img-wr {
  background: #00c4cc;
  position: unset;
  width: 224px;
  height: 224px;
  margin: 0 auto;
  border-radius: 100%;
}

.copyselection::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #8c1c4d;
}

.copyselection::selection {
  color: fff;
  background: #8c1c4d;
}
.iframe-link {
  min-height: 152px;
  background: #f9f6f4;
  border-radius: 5px;
  padding: 8px 14px;
  overflow-wrap: break-word;
}
/*Custom css by K*/
.tabs-image img {
  max-width: 100% !important;
  object-fit: cover !important;
}
.calenderProfileImg img {
  max-width: 100% !important;
  object-fit: cover !important;
}
/*Custom css by K for subscriptionlistview*/
.cancel-subscriptionlistview {
  color: #fff;
  background-color: #3a3432;
  padding: 2px 6px;
  border-radius: 4px;
}
.active-subscriptionlistview {
  color: #fff;
  background-color: #8c1c4d;
  padding: 2px 6px;
  border-radius: 4px;
}
.subscriptionlistview-date {
  font-weight: 600;
  font-size: 16px;
  color: #374151;
}
.subscriptionlistview-offer-time {
  font-weight: 400;
  font-size: 16px;
  color: #374151;
}
button#dropdownMenuButton-calendar {
  padding: 10px 10px;
}
.client-invoice-position {
  position: relative !important;
}
.past-invoice-images {
  position: absolute;
  right: 13px;
  top: 13px;
}
.btn-print-clientinvoice {
  padding: 7px 10px;
  font-weight: 600;
  font-size: 16px;
}
.profile-image-height {
  line-height: 38px !important;
  font-size: 16px !important;
  background-color: #f4eeeb !important;
  color: #8f807d !important;
}
.profile-image-obj {
  object-fit: cover;
}
.terms-and-condition-modal {
  max-height: 90vh;
  overflow-y: hidden;
}
.terms-and-condition-body {
  overflow-y: auto;
}
.no-service-checkbox {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
  margin-left: -20px;
}
.pac-container {
  z-index: 9999 !important;
}
.booking-link-logo {
  max-height: 160px;
  object-fit: cover;
}

.trial-expire-calendar-plan {
  width: 100vw;
  position: fixed;
  bottom: 0;
  background: #8c1c4d;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
}
.trial-expire-calendar-plan p {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px !important;
}
.plan-upgrade-calendar-btn {
  font-weight: 400;
  font-size: 16px;
  background-color: #f4eeeb;
  color: #8c1c4d;
}
.loader-spinner-center {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-user-circles {
  line-height: 30px !important;
}
.cancelled-sub-status {
  background-color: #c02942 !important;
}
a#billingdetails-tab {
  border-left: 1px solid #eee;
  border-radius: 0px;
}
.p-md-4 {
  padding: 3rem !important;
}
/* .h2, h2 {
  font-size: 18px !important;
} */

.height-dropdown {
  overflow-y: auto;
  /* max-height: 350px; */
  min-height: 195px;
  height: 100%;
}
.non-business-working-hours {
  background-color: hsla(0, 0%, 70%, 0.3) !important;
  border-color: hsla(0, 0%, 70%, 0.3) !important;
  opacity: 0.5;
  margin-right: -1px;
}
.custom-image-preview-css {
  object-fit: contain !important;
  background: #f9f6f4;
}
.supplier-grid-logo-customs img {
  object-fit: contain !important;
  background: #f9f6f4;
}
.truncate-td-products-tables {
  overflow: hidden;
  max-height: 3.2rem;
  line-height: 2.5rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: block;
}
.custom-bg-academy-categories {
  min-height: 100vh;
  background: #f9f6f4;
  padding: 38px 50px 0px 54px;
}
.background-service-campaigns {
  background-color: #8f8c8b !important;
}

.border-bottom-services-preview:not(:last-child) {
  border-bottom: 1px solid #eee;
  margin: 15px 0px;
  padding: 0 0 15px;
}

.drawer-body-setup {
  height: 100vh !important;
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn.btn-voucher {
  color: #fffefe !important;
  background: #8f807d !important;
}
.calender-loader-spinner-center {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  background: #040404;
  z-index: 1050;
  opacity: 0.2;
}
.card-event-full-calendar {
  margin-top: -3px;
}
.card-header-calendar-ui {
  background-color: #fbd2c3 !important;
}
.card-event-full-calendar {
  margin-right: -2px;
  margin-left: -2px;
  transform: translateY(-76%);
}
.smtp-position-lefts-btn {
  left: 12px !important;
}
.text-color-for-client-add {
  color: #8c1c4d !important;
}
.custom-margin-top-onlys {
  margin-top: 143px;
}
.height-auto {
  height: auto !important;
}
.card-logo-icon img {
  max-width: 100px !important;
  object-fit: cover;
}
.consultation-form-wrapper {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.consultation-form-wrapper .consultation-form-body {
  background-color: hsla(0, 0%, 100%, 0.9);
  min-height: 100vh;
  position: relative;
}
.width-height-products {
  height: 70px !important;
  width: 70px !important;
  object-fit: cover !important;
}
.custom-margin-consultation-link {
  margin-bottom: 112px !important;
}
.image-consultation-logo img {
  max-height: 160px;
  object-fit: cover;
}
.overflow-staff-working-hours {
  overflow-x: auto !important;
}
.ac-setup #v-pills-BookingLink {
  height: auto !important;
}
.custom-viewport-height-iframe {
  height: 91vh !important;
}
.fc-timegrid-event {
  border-radius: 0px !important;
}
.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0px !important;
}
.marketing-email-preview-title {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
}
.marketing-email-preview-description {
  font-weight: 400;
  font-style: 20px;
  line-height: 26px;
}
.marketing-email-preview-business_name {
  font-weight: 800;
  font-style: 16px;
  line-height: 18px;
}
.marketing-email-preview-business_address {
  font-weight: 400;
  font-style: 16px;
  line-height: 28px;
}
.new-sale-payments-border {
  padding: 8px 0px;
  margin: 16px 0px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.custom-height-oneoff-payment {
  height: 0px !important;
}
.custom-border-bottom-oneoff {
  border-bottom: 1px solid #000;
}
.esdev-app .nav-tabs.nav-justified > li {
  width: 50%;
  border: 1px solid #eee;
}
.custom-color-gray-btn {
  background-color: #999999 !important;
  color: #fff !important;
  border-radius: 22px;
}
.max-width-max-contents {
  max-width: auto !important;
}
.custom-icon-rounded {
  background-color: #bb5876;
  border-radius: 100%;
  min-height: 35px;
  min-width: 35px;
}
.esdev-app .sripo-watermark {
  display: none !important;
}
.sidenav-bar-close {
  width: 0 !important;
  position: absolute;
  left: -280px !important;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.upcoming-box-height {
  height: 924px;
}

/*Custom css by K*/
.formpreview-select-form {
  background-image: url(../images/drodown-icon.svg) !important;
  background-repeat: no-repeat;
  background-position: 97% center;
  padding-right: 30px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(31, 41, 55, 0.08);
  color: #374151;
  font-size: 14px;
  line-height: 1.3;
  padding: 8px;
  overflow-wrap: break-word;
}
.formpreview-textarea-form {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(31, 41, 55, 0.08);
  color: #374151;
  font-size: 14px;
  line-height: 1.3;
  padding: 8px;
  min-height: 92px;
  overflow-wrap: break-word;
}
.formpreview-textarea-form:hover {
  border-color: #9ca3af;
  box-shadow: 0 0 0 3px #e5e7eb;
}
.formpreview-select-form:hover {
  border-color: #9ca3af;
  box-shadow: 0 0 0 3px #e5e7eb;
}
.form-preview-width-control {
  overflow-wrap: break-word;
}
.invoice-tab-icon-position {
  position: absolute;
  top: 10px;
  left: 10px;
}
.max-width-height-box-sets {
  height: 300px;
  width: 300px;
}
.custom-email-preview-css {
  transform: scale(0.6);
  transform-origin: top left;
  max-height: 650px;
}
.preview-container-widths {
  width: 100%;
  overflow: auto;
  margin-bottom: 24px;
}
.custom-staff-access-modal {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-staff-access-modal::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}
.custom-staff-access-modal::-webkit-scrollbar {
  width: 12px;
}
.custom-button-setup-class {
  padding: 17px 0px;
}

.maxwidth-auto {
  max-width: none !important;
}

.new-service-scroll-bar {
  overflow: scroll;
}
.tick-mark-svg-set {
  width: 12px;
  object-fit: cover;
  display: flex;
  align-items: flex-start;
  justify-content: end;
}
.image-staff-targets .width-height-staff-targets {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.progress-circle {
  position: relative;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  overflow: hidden;
}

.progress-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #f8ae44;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}

.progress-circle-description {
  text-align: center;
  margin-top: 10px;
}

.text-color {
  color: #f8ae44;
}

/* menu icon */
.mobile-menu-icon {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s;
}

.mobile-menu-icon span {
  display: block;
  height: 3px;
  width: 30px;
  background-color: #8c1c4d;
  margin-bottom: 4px;
  transition: transform 0.3s, opacity 0.3s;
}

.mobile-menu-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(7px, 6px);
}

.mobile-menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(3px, -3px);
}
.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}

.backdrop.show {
  display: block;
}
/* menu icon */
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.bg-Apricot {
  background: #ffc1b0 !important;
}
.text-light-whites {
  color: #fff !important;
}
.disabled-div-style {
  background-color: #e9ecef;
  opacity: 1;
}
/* Staff Targets */
.custom-border-box-image {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
}
.cost-rates .service-items,
.cost-rates .totals {
  display: flex;
  flex-wrap: wrap;
}
.cost-rates .service-items div,
.cost-rates .totals div {
  padding: 0 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.cost-rates .service-items div:not(:last-child),
.cost-rates .totals div:not(:last-child) {
  border-right: 1px solid #dee2e6;
}
.texts-lights-pinks {
  color: #fbd2c3;
}
.texts-lights-pinks:hover {
  color: #f9f6f4;
}
.progress-bar-custom-toasts {
  background-color: #fbd2c3;
}
.progress-bar-toast-height {
  height: 9px;
}
.bg-cream-important {
  background-color: #f9f6f4 !important;
}
.staff_mod_customs_wrapp {
  min-height: 100vh;
  margin: 0 auto;
}
.staff_mod_customs_wrapp .modal-content {
  min-height: calc(100vh - 6rem);
  margin: 3rem 0;
}
.staff_mod_customs_wrapp .custom-staff-access-modal {
  max-height: 559px;
}
/* .fc-scrollgrid thead{
  display:flow-root;
  padding-top: 50px !important;
} */
.calenderProfileImg {
  padding-top: 5px;
}
/* CSS for the cog icon (the Link) */
.position-relative a {
  cursor: pointer;
  /* Add any other styles you want for the cog icon */
}

/* CSS for the parent container of the dropdown */
.position-relative {
  position: relative;
}

/* CSS for the transparent backdrop */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
}
/* CSS for the dropdown menu */
.dropdown-menu-show {
  position: absolute;
  top: calc(100% + 8px);
  right: 12px;
  background-color: #fff;
  border: 0.5px solid #a2a7ae;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(31 41 55/6%), 0 4px 6px rgba(31, 41, 55, 0.1);
  padding: 8px;
  width: 200px;
  z-index: 10;
  overflow: auto; /* Add this to show scrollbar when content overflows */
  max-height: 200px; /* Set the maximum height of the dropdown */
}
.backdrop.show.z-index-1021 {
  z-index: 1021;
}
.dropdown-menu-show.custom-staff-drop-designs {
  position: absolute;
  background-color: #fff;
  top: calc(100% + 1px);
  right: 0px;
  border: 0.5px solid #a2a7ae;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(31 41 55/6%), 0 4px 6px rgba(31, 41, 55, 0.1);
  padding: 8px;
  width: 100%;
  z-index: 10;
  overflow: auto;
  max-height: 200px;
}
.min-width-calendars-200 {
  min-width: 200px;
}
.extra-staff-dropdown-customs-css {
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  padding: 6px 25px 6px 8px;
}
.extra-staff-dropdown-customs-paddings {
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  padding: 8px 25px 8px 8px;
}
.dropdown-menu-show.no-left-zero.overflow-content-manages {
  width: 246px;
}
.box-image-cover .setting-dropdown.right-zero-imp {
  right: -16px;
  top: -26px;
}
/* CSS for the dropdown item links */
.dropdown-menu-show a.dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #333; /* Text color for the dropdown items */
}

/* CSS for the dropdown item links on hover */
.dropdown-menu-show a.dropdown-item:hover {
  background-color: #f9f6f4; /* Background color on hover */
  color: #333; /* Text color on hover */
  font-weight: 600;
  color: #8c1c4d !important;
}
/* sweet alerts customizations */
/* .swal2-confirm{
  border-radius: 4px !important;
  box-shadow: 0 1px 2px rgba(31,41,55,.08) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  background-color: #8c1c4d !important;
  border-color: #8c1c4d !important;
  color: #fff !important;
}
.swal2-confirm:hover{
  background-color: #FFF !important;
  outline: 1px solid #8c1c4d !important;
  color: #8c1c4d !important;
}
.swal2-popup.swal2-modal.swal2-show{
  padding-bottom: 0px !important;
  border-radius: 8px;
}
.swal2-actions{
  padding: 8px 0px !important;
  background: #F4EEEB !important;
  width: 100% !important;
  border-radius: 0px 0px 8px 8px;
} */
/* sweet alerts customizations */
ul.nav.nav-tabs.nav-justified.esdev-settings-tabs {
  width: 100% !important;
}
ul.nav.nav-tabs.nav-justified.esdev-settings-tabs li {
  width: 50% !important;
}
/* @media only screen and (max-width: 991px) {
  .drawer-footer {
    padding-top: 35px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .drawer-wrp.position-relative form {
    position: relative;
    height: calc(100vh - 65px);
  }
  // second method
   .drawer-wrp.position-relative form {
    height: calc(100vh - 22px);
    position: relative;
}
.drawer-footer {
    padding-top: 35px;
    position: absolute;
    width: 100%;
    bottom: 34px;
}
} */
.dropdown-menu-show.no-left-zero.custom-dropdown-profile-width {
  width: 310px;
}
/* .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
} */
/* .table-style-client-import th{
  padding: 20px 0px;
  font-weight: 900;
  font-size: 18px;
} */
.box-client-imports {
  overflow: auto;
}
.bg-beige {
  background: #fcf7f8 !important;
}
.exclaimation-marks {
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
}
/* chat message designs */
.custom-drawer-chat-msg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.client-detaildrawer .content-wrp.content-chat-msg {
  height: calc(100vh - 280px) !important;
}
.form-control.client-chat-textarea {
  background-color: #f7f7f7;
  border: none !important;
}
.voucher-box.voucher-box-overlaps {
  overflow-wrap: break-word;
}
.message-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.message-box.right {
  align-items: flex-end;
}

.message-box.left {
  align-items: flex-start;
}

.message {
  background-color: #feeef3;
  color: #333;
  border-radius: 10px;
  padding: 10px;
  max-width: 70%;
  text-align: left;
  overflow-wrap: break-word;
}

.message.right {
  background-color: #f9f6f4;
  text-align: right;
}

.message-date {
  font-size: 12px;
  color: #777;
}
/* chat message designs */
/* CustomSwal like modal */
.warning-logo-icon img {
  width: 40px;
  height: auto;
  object-fit: contain;
}
.custom-alerts-popups ul li {
  background-image: none;
}
.btn.btn-apricots {
  color: #fff !important;
  background-color: #ffc1b0 !important;
  border-color: none !important;
  border-radius: 4px;
}
.btn.btn-apricots:hover {
  color: #040404 !important;
  background-color: #f9f6f4 !important;
  border-color: #cccccc !important;
}
.photo-upload-btn {
  font-weight: normal;
  font-size: 16px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 2px;
  line-height: normal;
  padding: 9px 130px;
  color: #131313 !important;
  background-color: #f9f6f4 !important;
  border-color: #f9f6f4 !important;
  border-radius: 4px;
  white-space: nowrap;
}
.photo-upload-btn:hover {
  color: #040404 !important;
  background-color: #e9e9e9 !important;
  border-color: #8c1c4d !important;
}
.btn.footer-custom-btn {
  color: #fff !important;
  background-color: #8c1c4d !important;
  border-color: none !important;
  font-size: 11px;
  border-radius: 4px;
}
.btn.footer-custom-btn:hover {
  color: #040404 !important;
  background-color: #f9f6f4 !important;
  border-color: #8c1c4d !important;
}
.btn.btn-light-gray {
  color: #040404 !important;
  background-color: #f1f1f1 !important;
  border-color: none !important;
  border-radius: 4px;
}
.btn.btn-light-gray:hover {
  color: #040404 !important;
  background-color: #fff !important;
  border-color: #cccccc !important;
}
.btn.light-beige {
  color: "#6B7280" !important;
  background-color: #f9f6f4 !important;
  border-color: none !important;
  border-radius: 4px;
}
.btn.light-beige:hover {
  color: "#6B7280" !important;
  background-color: #fff !important;
  border-color: #cccccc !important;
}
.btn.light-pinks-shade {
  color: "#6B7280" !important;
  background-color: #fef1ed !important;
  border-color: none !important;
  border-radius: 4px;
}
.btn.light-pinks-shade:hover {
  color: "#6B7280" !important;
  background-color: #fff !important;
  border-color: #cccccc !important;
}
.border-bottom-grays {
  border-bottom: 1px solid #eee;
  padding: 0 0 20px;
  margin: 0 0 30px;
}
.z-index-9999999 {
  z-index: 9999999 !important;
}
#pay-by-stripe-modal {
  z-index: 99999 !important;
}
.modal-backdrop.show.custom-pay-by-stripe {
  z-index: 9999 !important;
}
.floating-label-chat-msg {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fffbd7;
  padding: 0 5px;
  z-index: 1;
  border-radius: 6px;
  transition: all 0.3s ease;
}
.floating-label-chat-msg:focus,
.floating-label-chat-msg:active {
  top: -15px;
  font-size: 12px;
  font-weight: bold;
  background-color: transparent;
}
.profile-image-height.line-heights-adjust-stafftarget {
  line-height: 59px !important;
}
.custom-spinner-set-stafftarget {
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-button {
  position: relative;
}

.circle-import-notes {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #feeef3; /* Set your desired background color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle-import-notes-image {
  width: 96px;
  height: 91px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 24px;
  left: 21px;
}
.btn-import-notes-position-child {
  position: absolute !important;
  z-index: 999 !important;
  bottom: 14px;
  left: 26px;
}

img {
  max-width: 100%;
  width: auto;
}
.btn.btn-appointments:disabled {
  color: #fff !important;
  background: #eeeeee !important;
  border: none !important;
}
.btn.btn-light-gray {
  color: #131313 !important;
  background: #eeeeee !important;
  /* border: none !important; */
}
.drawer-body.drawer-body-height-adjust {
  height: calc(100vh - 258px) !important;
}
.cursor-default {
  cursor: default !important;
}
/* [aria-describedby="slot-time-tooltip"] {
  width: "450px";
  max-width: "450px";
} */
.signup-header.custom-signup-header {
  padding: 35px 0px 0px 0px;
}
.signup-header-paddings.custom-padding-header {
  padding: 0px 35px;
}

.client-detaildrawer .content-wrp.custom-content-wrp {
  overflow-y: hidden;
}
.bg-none {
  background: none !important;
  background-color: none !important;
}
.roster_time_off {
  background-color: #fefbd7 !important;
}
#roster table .roster_time_off a {
  color: #fefbd7 !important;
}
#roster table .roster_time_off .updated-time {
  color: #cfdcf0 !important;
}
#roster table .roster_time_off .updated-time p {
  font-size: 12px;
  color: #cfdcf0 !important;
}
.dropdown-item-calendars {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #333; /* Text color for the dropdown items */
}

/* CSS for the dropdown item links on hover */
.dropdown-item-calendars:hover {
  background-color: #f9f6f4; /* Background color on hover */
  color: #333; /* Text color on hover */
  font-weight: 600;
  color: #8c1c4d !important;
}
.custom-notification-badges.custom-notification-hw-adjust {
  width: 24px;
  height: 24px;
  line-height: 15px;
}
.drawer .drawer-body.custom-drawer-height-notification {
  height: calc(100vh - 104px);
}
.box-image-cover.notification-boxs {
  padding: 35px 26px;
  margin: 0px;
  box-shadow: none;
  border: none;
  margin-bottom: 0px;
}
.wh-40.mwh-40 {
  min-width: 40px !important;
  min-height: 40px !important;
}
.custom-notification-badges {
  width: 20px;
  height: 20px;
  line-height: 11px;
  font-size: 11px;
  text-align: center;
  z-index: -1;
}
.drawer-body.custom-drawer-height-notification .custom-drawer-buttons-notification-bar {
  position: absolute;
  bottom: 0;
  background: #eee;
}
.btn.btn-pills {
  border-radius: 18px !important;
}
.btn.padding-x-2 {
  padding: 0 1.8rem !important;
}
.user-initial.client-user-circles.custom-client-user-circles-lh {
  line-height: 29px !important;
}
.modal-upgrade-container.sms-run-out-alert-container {
  max-width: 907px !important;
}
.modal-upgrade-ac-wrapper.sms-out-alert-wrapper {
  padding: 66px 0px 66px 0px !important;
}
.marketing-mobile-birthday.campaign-preview-wh-changes {
  max-width: 100% !important;
  min-height: 950px !important;
}
.online-booking-content.booking-widget-wrapper {
  max-width: 100% !important;
  /* height: 100% !important; */
  /* min-height: calc(100vh - 184px) !important; */
}
.justify-content-center {
  justify-content: center !important;
}
.badge.unpaid {
  background-color: #dc3545; /* Bootstrap danger color */
  color: #fff; /* White text color */
}
.modal-upgrade-container.sms-run-out-alert-container {
  max-width: 907px !important;
}
.calendar-resource-brand-logo {
  width: 14px;
  height: 14px;
  object-fit: contain;
}
.stripe-table-row-bg {
  background: #f9f6f4 !important;
  background-color: #f9f6f4 !important;
}
.position-relative.close-marker-resource-btn a {
  position: relative;
  cursor: pointer;
  top: 3px;
  right: -35px;
  font-size: 24px;
  font-weight: 100 !important;
}
.cancel-subscriptionlistview.cancel-btn-sub {
  background-color: #bdbdbd;
}
.badge.unpaid {
  background-color: #dc3545;
  color: #fff;
}
.table:not(.custom-table) > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.8);  */
  z-index: 101;
}
.custom-loader-css {
  padding: 80px;
  background: #fff;
  box-shadow: 1px 1px 10px grey;
  border-radius: 8px;
  z-index: 9999;
}
.custom-bar-loader-calendar {
  bottom: 0;
}
.calendars-close-drawer {
  position: absolute;
  top: 23px;
  right: 23px;
}
.position-absolute.custom-position-calendar {
  top: 7px;
  left: 7px;
}
.spinner-border.spinner-border-color-custom {
  color: rgba(140, 28, 77, 0.7);
}
.items-option.nodatafound-service {
  background: #eeeeee;
  color: #777;
}
.card-event-full-calendar.height-adjustments {
  transform: translateY(-65%);
}

.sidebar-beta-icon-label {
  display: block;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
}
.resource-beta-version-ads-header {
  padding: 0px 22px;
  width: 100%;
  color: #fff;
  top: 0;
  background: #27a2f8;
  min-height: 40px;
}
.page-content.page-content-pt-adjust {
  padding-top: 111px !important;
}
.setting-dropdown.client-product-report-image li a img {
  max-width: none !important;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.dropdown-menu-show.client-product-report-dropdown {
  min-width: 300px !important;
}
.signature-pad-wrapper {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}
.bg-gray-disabled {
  background: #f3f4f6 !important;
}
.custom-visible-scrollbar {
  /* Set overflow to auto to enable scrolling */
  overflow: auto !important;

  /* Define scrollbar width and color */
  scrollbar-width: 8px !important;
  scrollbar-color: #8c1c4d transparent !important;

  /* Define scrollbar track color */
  scrollbar-track-color: #fbd2c3 !important;
}

/* Define scrollbar thumb styles */
.custom-visible-scrollbar::-webkit-scrollbar {
  width: 12px !important; /* Adjust as needed */
}

.custom-visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: #8c1c4d !important;
  border-radius: 6px !important; /* Adjust as needed */
}

/* Define scrollbar thumb styles for Firefox */
.custom-visible-scrollbar {
  scrollbar-width: 8px !important;
  scrollbar-color: #8c1c4d transparent !important;
}
.signature-pad-wrapper.box-shadow-none{
  box-shadow: 0px 0px 0px 0px;
}
.close-drawer.custom-sale-adjustments{
  top:17px !important;
  right:26px !important;
}
/* CustomSwal like modal */
@media (max-width: 1200px) {
  .position-relative.close-marker-resource-btn a {
    right: 10px !important;
  }
}
@media only screen and (max-width: 1059px) {
  .header-calender-week {
    margin-top: 42px;
  }
}
@media (max-width: 907px) {
  .calenderProfileImg {
    padding-top: 37px !important;
  }
}
@media (max-width: 1059px) and (min-width: 992px) {
  .calenderProfileImg {
    padding-top: 54px !important;
  }
}
@media (min-width: 768px) {
  .cost-rates .service-items,
  .cost-rates .totals {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .cost-rates .service-items div,
  .cost-rates .totals div {
    width: 33.33%;
  }
  .border-bottom-grays {
    padding: 0 0 10px;
    margin: 0 0 23px;
  }
  .modal-upgrade-ac-wrapper.sms-out-alert-wrapper {
    padding: 66px 0px 126px 0px !important;
  }
}
/* Calendar Header Css */
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
  top: 66px !important;
}

/* Calendar Header Css */
.report-sale-end-of-day-ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; /* Add margin between sections */
}

.report-sale-end-of-day-li {
  font-size: 18px;
  padding: 12px 8px; /* Optional: Adjust padding as needed */
}

.report-sale-end-of-day-li:not(:last-child) {
  border-bottom: 1px solid #ccc; /* Adjust color and thickness as needed */
}

.highlight-sale-eod {
  background: #F8F8F8; /* Light grey background for highlighted items */
}

/* General radio button styling */
.form-control-radio {
  position: absolute;
  opacity: 0;
}

/* Default appearance for unchecked radio buttons */
.form-control-radio + label::before {
  content: '';
  background: #f4f4f4; /* Light background for unchecked radio */
  border-radius: 100%;
  border: 1px solid #cccccc;
  display: inline-block;
  width: 18px; /* Adjusting size */
  height: 18px;
  position: relative;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  transition: all 250ms ease;
}

/* Checked state - applies background color and inner circle effect */
.form-control-radio:checked + label::before {
  background-color: #8c1c4d; /* Selected radio color */
  box-shadow: inset 0 0 0 4px #f4f4f4; /* Inner circle effect */
}

/* Focused state - outline effect */
.form-control-radio:focus + label::before {
  outline: none;
  border-color: #8c1c4d; /* Focus border color */
}

/* Disabled state */
.form-control-radio:disabled + label::before {
  background-color: darken(#f4f4f4, 25%);
  border-color: darken(#f4f4f4, 25%);
  box-shadow: none;
}
/* Staff Targets */
@media (min-width: 767px) and (max-width: 848px) {
  .custom-email-preview-css {
    transform: scale(0.5); /* Zoom out the content by 80% */
  }
}

@media (min-width: 1481px) and (max-width: 1562px) {
  .dashboard .left-col ul {
    height: 856px;
  }
  .upcoming-box-height {
    height: 950px;
  }
}
@media (min-width: 1400px) and (max-width: 1480px) {
  .dashboard .left-col ul {
    height: 882px;
  }
  .upcoming-box-height {
    height: 975px;
  }
}
@media (min-width: 1241px) and (max-width: 1399px) {
  .dashboard .left-col ul {
    height: 948px;
  }
  .upcoming-box-height {
    height: 1041px;
  }
}
@media (min-width: 1200px) and (max-width: 1241px) {
  .dashboard .left-col ul {
    height: 923px;
  }
  .upcoming-box-height {
    height: 1040px;
  }
}
@media (min-width: 401px) and (max-width: 567px) {
  .dropdown-menu-show.no-left-zero-client-grid.custom-set-left-side {
    left: -200px;
    right: -0px;
  }
}
@media only screen and (max-width: 400px) {
  .brithday-mail-smstext {
    max-width: 230px;
  }
  .container-everyday-market-banners {
    padding-left: 31px !important;
    padding-right: 30px !important;
  }
  .dropdown-menu-show.no-left-zero-client-grid {
    left: 108%;
    transform: translateX(-78%);
  }
  .dropdown-menu-show.no-left-zero-client-grid.custom-set-left-side {
    left: 100%;
    transform: translateX(-100%);
  }
}
@media only screen and (max-width: 567px) {
  .page-content.custom-pt-top-content{
    padding-top: 72px;
  }
  .header-calender-week {
    margin-top: 0px;
  }
  .fc .fc-scrollgrid-section-sticky > * {
    position: none !important;
  }
  .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
    top: 0px !important;
  }
  /* .sidemenu {
    height: auto !important;
  } */
  .overflow-y-client-detail-auto {
    overflow-y: auto !important;
  }
  .dropdown-menu-show {
    right: unset;
    left: 0; /* Position the dropdown at the left edge of its parent container */
  }
  .dropdown-menu-show.no-left-zero {
    left: unset;
    right: 0; /* Position the dropdown at the right edge of its parent container */
  }
  .photo-upload-btn {
    padding: 9px 60px;
  }
}
@media only screen and (max-width: 768px) {
  .custom-email-preview-css {
    transform: scale(0.5); /* Zoom out the content by 80% */
  }
  .client-user-circles {
    line-height: 50px !important;
  }
  a#billingdetails-tab {
    border-left: none;
  }
  .custom-margin-top-onlys {
    margin-top: 0px !important;
    margin-bottom: 100px;
  }
  .purchase-sms-custom-heights {
    height: max-content !important;
  }
  .voucher-preview {
    width: 100% !important;
  }
  .overflow-x-auto {
    overflow-x: auto !important;
  }
  .border-top-0 {
    border-top: 0px !important;
  }
  .staff_mod_customs_wrapp .custom-staff-access-modal {
    max-height: 559px;
  }
  .overflow-after-medium-screen {
    overflow: auto !important;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .brithday-mail-smstext {
    max-width: 283px;
  }
}
