$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables
// fusv-disable
// scss-docs-start gray-colors-map
$grays: ( "100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, "900": $gray-900) !default;
// scss-docs-end gray-colors-map
// fusv-enable
// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$themePrimary: #8c1c4d !default;
$themeTextColor: #374151 !default;
$themeSecondary: #EEEEEE !default;
$themeDanger: #EF0000 !default;
// scss-docs-end color-variables
// scss-docs-start colors-map
$colors: ( "blue": $blue, "indigo": $indigo, "purple": $purple, "pink": $pink, "red": $red, "orange": $orange, "yellow": $yellow, "green": $green, "teal": $teal, "cyan": $cyan, "white": $white, "gray": $gray-600, "gray-dark": $gray-800, "themePrimary": $themePrimary) !default;
// scss-docs-end colors-map
// scss-docs-start theme-color-variables
$primary: $themePrimary !default;
$secondary: $themeSecondary !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $themeDanger !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;