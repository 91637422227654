@import "react-variables";
.h-custom {
  height: calc(100% - 73px);
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

// .btn-primary {
//     background-color: $primary;
//     border-color: $primary;
// }
// .btn-primary:hover {
//     color: $primary;
//     background-color: $white;
//     border-color: $primary;
// }
// .btn.btn-outline-primary {
//     background: none;
//     color: $primary;
// }
// .btn.btn-primary-outline.active {
//     color: $primary;
// }
.is-invalid .css-1s2u09g-control {
  border-color: $danger;
}
// .was-validated .form-control:invalid,
// .form-control.is-invalid {
//   padding: 8px !important;
// }

.form-check-label {
  color: $gray-800;
}

.my-custom-react-select__menu {
  z-index: 2;
}

.Select.is-open {
  position: relative;
  z-index: 1000;
}

.my-custom-react-select__control {
  border-width: 1px !important;
  border-color: #cbd5e0 !important;
}

.my-custom-react-select__control--is-focused {
  border-color: #746df7 !important;
  box-shadow: none !important;
}

.my-custom-react-select__indicator-separator {
  display: none;
}

.toprightclose {
  padding: 3px 9px !important;
  background: $primary;
  border: $primary;
  color: $white;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 5px;
  @media screen and (max-width: 767px) {
    right: -70px;
    top: -67px;
  }
  @media screen and (min-width: 768px) {
    right: -88px;
    top: -100px;
  }
}

.h-20 {
  height: 20px !important;
}

.wh-118 {
  width: 118px !important;
  height: 118px !important;
}

.wh-40 {
  width: 40px !important;
  height: 40px !important;
}

.wh-32 {
  width: 32px !important;
  height: 32px !important;
}
.wh-36 {
  width: 36px !important;
  height: 36px !important;
}
.wh-60 {
  width: 60px !important;
  height: 60px !important;
}
.user-initial.wh-36 {
  line-height: 36px !important;
}
.search .search-result {
  max-height: 210px !important;
  overflow-y: auto !important;
}

.box-image-cover a.client-detail {
  color: $themeTextColor;
  text-decoration: none;
  &:hover {
    color: $themePrimary;
  }
}

.box-image-cover a.supplier-detail {
  color: $themeTextColor;
  text-decoration: none;
  &:hover {
    color: $themePrimary;
  }
}

header {
  z-index: 1049 !important;
}

.drawer,
.full-screen-drawer {
  z-index: 1050 !important;
}

.hover-primary {
  &:hover {
    color: $themePrimary !important;
  }
}

.drawer-supplier-hr {
  color: $secondary;
  margin: 40px 0;
}

.text-decoration-dotted {
  text-decoration: underline dotted !important;
}

.addstaff-member .service ul {
  margin-top: 15px !important;
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu,
  .table-responsive .dropdown-toggle {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}

.service_table_header {
  vertical-align: middle;
}

.addstaff-member .input-file input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 1;
}

.image-preview {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
}

.table > :not(:first-child) {
  border-top: none;
}

.client_photo input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 1;
}

.photoform {
  display: inline;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.working-hrs .was-validated .form-control:invalid,
.working-hrs .form-control.is-invalid {
  padding: 8px;
  background-image: none;
}

.rosterlistviewtable tr th.active {
  background: $primary;
  color: $white;
}

.drawer-footer .form-check {
  min-height: 1.5rem !important;
  margin-bottom: 0.125rem !important;
}

.calenderProfileImg {
  text-align: center;
}

.calenderProfileImg img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.calenderProfileImg .user-initial {
  font-weight: 300;
color: #8c1c4d;
text-transform: uppercase;
background-color: #f9f6f4;
font-size: 16px;
height: 45px;
width: 45px;
line-height: 45px;
margin: auto;
}

.calenderProfileImg .image-content {
  font-weight: normal;
  padding-top: 5px;
}

.calendar_event_content li {
  color: $white;
  margin-bottom: 2px;
}

.drawer.appointment-detail .include-footer .drawer-body {
  height: calc(100vh - 290px) !important;
}

/*-Calender Css */

// .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
//   top: 66px !important;
// }

.fc .fc-col-header-cell-cushion {
  padding: 20px 4px !important;
}

.fc .fc-col-header-cell.active {
  background: $primary;
  color: #fff;
}

.voucher-avilable {
  overflow-y: auto;
  height: calc(100vh - 81px);
}

.spinner-border {
  margin-right: 1px;
}

.object-fit-none {
  object-fit: unset !important;
}

.working-hrs .table > :not(caption) > * > * {
  padding: 10px 0 !important;
}

.search-address {
  position: relative;
}

.search-address input {
  text-indent: 25px;
}

.search-address .fa-search {
  position: absolute;
  top: 12px;
  left: 12px;
}

.searchbox {
  position: relative;
  color: #9ca3af;
}

.searchbox input {
  text-indent: 25px;
  border-color: #fff;
  background-color: #f6f6f6;
  border-radius: 40px !important;
  box-shadow: 0 0 0 3px #ffffff;
  font-size: 16px;
  border: 0px;
}

.searchbox input:focus {
  box-shadow: none;
  border-color: #fff;
  background-color: #f6f6f6;
  font-size: 16px;
  border: 0px;
}

.searchbox input:hover {
  box-shadow: none;
}

.searchbox .fa-search {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #9ca3af;
}

.searchbox a.close {
  position: absolute;
  top: 6px;
  right: 16px;
  color: #9ca3af;
  font-size: 20px;
}

//searchbox client
.searchbox-square {
  position: relative;
  color: #9ca3af;
}

.searchbox-square input {
  text-indent: 25px;
}

.searchbox-square.price input {
  text-indent: 16px;
}

.searchbox-square .fa-search,
.searchbox-square .fa-dollar-sign {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #9ca3af;
}

.searchbox-square a.close {
  position: absolute;
  top: 6px;
  right: 16px;
  color: #9ca3af;
  font-size: 20px;
}

//searchbox client
.searchbox-square-large {
  position: relative;
  color: #9ca3af;
}

.searchbox-square-large input {
  text-indent: 45px;
  padding: 15px;
  font-size: 23px;
  font-weight: 300;
  font-family: "Source Sans Pro", sans-serif;
  color: #999;
}

.searchbox-square-large .fa-search {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #9ca3af;
  font-size: 23px;
}

.searchbox-square-large a.close {
  position: absolute;
  top: 16px;
  right: 20px;
  color: #9ca3af;
  font-size: 23px;
}

.react-datalist-input__container > * {
  box-sizing: border-box;
}

.react-datalist-input__container {
  width: 100%;
  position: relative;
  --shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --gray: rgb(200, 200, 200);
  --padding: 5px;
  --border-radius: 5px;
  --border: 1px solid var(--gray);
}

.react-datalist-input__textbox {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  padding: var(--padding);
  border: var(--border);
  border-radius: var(--border-radius);
}

.react-datalist-input__listbox {
  width: 100%;
  position: absolute;
  margin: 2px 0 0 0;
  padding: 0;
  border: none;
  border-radius: var(--border-radius);
  background-color: white;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  box-shadow: var(--shadow);
  z-index: 10;
}

.react-datalist-input__listbox-option {
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0 15px;
  line-height: 40px;
  font-size: 16px;
}

.react-datalist-input__listbox-option:focus {
  background-color: var(--gray);
}

.react-datalist-input__listbox-option:hover {
  background-color: var(--gray);
}

.fs-18 {
  font-size: 18px;
}
.horizontal-scrollable > .row {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable > .row > .col-xs-4 {
  display: inline-block;
  float: none;
}
/*# sourceMappingURL=styles.css.map */

.customswal .swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: initial;
  background-color: #f43f5e;
  color: #fff;
  font-size: 1em;
  padding: 8px 16px;
  gap: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.customswal .swal2-styled.swal2-cancel,
.customswal .swal2-styled.swal2-deny {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  background: initial;
  background-color: initial;
  background-color: #ffffff;
  color: #374151;
  font-size: 1em;
  padding: 8px 16px;
  gap: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
