:root {
  --pink-color: rgba(251, 210, 195, 0.3);
}
/* canva page start  */
.canva-wrapper {
  background-color: #f9f6f4;
  padding: 45px 100px 160px 68px;
}

.canva-des p {
  font-size: 18px;
  line-height: 28px;
}

.canva-img {
  width: 124px;
  height: 124px;
}

.canva-img img {
  max-width: 124px;
}

.canva-des {
  width: calc(100% - 124px);
  padding-left: 46px;
}

.canva-inner-des {
  max-width: 1256px;
}
.canva-top-row {
  margin-bottom: 80px;
}

.canva-wrapper .swiper {
  width: 100%;
  height: 100%;
}

.canva-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.canva-wrapper .swiper-scrollbar-drag {
  background-color: #bb5876;
  /* max-width: 185px; */
  border-radius: 20px;
}

.canva-wrapper .swiper-scrollbar {
  background: #c4c4c4;
  border-radius: 20px;
  height: 11px;
  opacity: 1 !important;
}
.canva-wrapper .swiper-wrapper {
  margin-bottom: 90px;
}

/* canva mobile css  */
.swiper-mob-main {
  overflow: hidden;
  position: relative;
  border-radius: 45px;
}

.canva-wrapper .swiper-slide .swwiper-main-img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 492px;
  object-fit: cover;
}

img.child-content-img {
  position: absolute;
  top: 11px;
  left: 6px;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 95%;
  height: 95%;
}

/* canva page End */
/* online booking page start   */
.online-booking-wrapper {
  background-color: #f9f6f4;
  padding: 36px 0 41px;
  height: 100%;
}
.online-booking-content {
  padding: 42px 41px 44px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.booking-logo {
  margin-bottom: 17px;
}

.select-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  display: block;
  color: #374151;
}
.please-sele {
  margin-bottom: 14px;
}

.select-btn img {
  margin-right: 11px;
}
.items-option {
  background: #fcfaf9;
  transition: all 0.4s;
  border-radius: 5px;
  padding: 15px 16px 15px 30px;
}
.items-option:hover {
  background-color: var(--pink-color);
}
.items-option:not(:last-child) {
  margin-bottom: 8px;
}
.items-option h6 {
  font-weight: 600;
}

.power-be p {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.items-list ul {
  list-style: none;
}
.select-btn img {
  width: 23px;
}
.power-be p a {
  color: #8c1c4d;
}
li.items-option p a {
  color: #8c1c4d;
  text-decoration: underline;
}
li.items-option.active {
  background-color: var(--pink-color);
}
li.items-option.active h6 {
  font-weight: 400;
}

/* date time template css  */
.date-time {
  padding-top: 31px;
}
.time-select h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.time-select {
  max-width: 289px;
  margin-left: auto;
  max-height: 230px;
  overflow-y: scroll;
}

/* Webkit scrollbar styles */
.time-select::-webkit-scrollbar {
  width: 14px;
}

.time-select::-webkit-scrollbar-track {
  background: linear-gradient(0deg, #f9f6f4, #f9f6f4), #f9f6f4;
  -webkit-background: linear-gradient(0deg, #f9f6f4, #f9f6f4), #f9f6f4;
  border-radius: 12px;
  -webkit-border-radius: 12px;
}

.time-select::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #f0e5e0, #f0e5e0), #f9f6f4;
  border-radius: 12px;
}

::-webkit-scrollbar-track-piece {
  background-color: linear-gradient(0deg, #f9f6f4, #f9f6f4), #f9f6f4;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:vertical {
  background: linear-gradient(0deg, #f0e5e0, #f0e5e0), #f9f6f4;
  border-radius: 12px;
}

/* Firefox scrollbar styles */
.time-select {
  scrollbar-color: #f0e5e0 #f9f6f4;
  scrollbar-width: thin;
}


.time-select::-moz-scrollbar {
  width: 14px;
}

.time-select::-moz-scrollbar-track {
  background-color: #f9f6f4;
  border-radius: 12px;
}

.time-select::-moz-scrollbar-thumb {
  background-color: #f0e5e0;
  border-radius: 12px;
}
.time-select li {
  border: 1px solid #f4eeeb;
  text-align: center;
  margin-bottom: 11px;
  padding: 9px 5px;
  font-weight: 400;
  transition: all 0.4s;
  font-size: 16px;
  line-height: 20px;
}
.time-select li:hover {
  background-color: var(--pink-color);
}

.items-option .w-21 {
  width: 36px;
  height: 36px;
}
.user-img-name li.items-option {
  padding: 10px 16px 10px 30px;
}

/* confrim from  */
.confirm-from-cover {
  max-width: 461px;
  width: 100%;
  margin: 35px auto 0;
}
.bookin-mess h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.sm-icon {
  width: 13px;
}
/* online booking page End  */

/* *online booking widget vouchers 1 start*=== */
select.form-control {
  /* background-image: url(./images/drodown-icon.svg); */
  background-repeat: no-repeat;
  background-position: 97% center;
  padding-right: 30px;
}
/* chek btn css  start*/
.chek-box {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 1;
}
.custom-chek {
  position: relative;
}
.custom-chek:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
}
.chek-box:checked + .custom-chek:before {
  background-color: #8c1c4d;
}
.chek-box:checked + .custom-chek:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 7px;
  color: #fff;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(37deg);
}
/* chek btn css  End*/
.member-des {
  max-width: 518px;
}
.form-switch .form-check-input {
  background-image: url("../images/switch-btn.png");
}
.pay-btn {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  background: #8c1c4d;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #8c1c4d;
  padding: 23px 14px;
  transition: all 0.4s;
}
.pay-btn:hover {
  background-color: #fff;
  color: #8c1c4d;
}
.pay-card-no .form-control {
  border-radius: 4px 4px 0 0;
}
.pay-card-no .form-control .date-wr .form-control {
  border-radius: 0 0 0 4px;
}
.cvc-wr .form-control {
  border-radius: 0 0 4px 0;
}

.pay-card-no {
  position: relative;
}

.pay-option {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-8%, -50%);
}
.pay-option button {
  background-color: transparent;
}
.cvc-wr {
  position: relative;
}

button.cvc-btn {
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-22%, -50%);
}
.online-booking-content input.form-control,
.online-booking-content select.form-control,
.online-booking-content textarea.form-control {
  min-height: 44px;
}

/* custom scroll bar  */
.full-img-larg-text {
  position: absolute;
  top: 108px;
  left: 0;
  right: 0;
  margin: 0 -8px 0 0;
  max-height: 610px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.full-inner-cover {
  max-width: 260px;
  margin: 0 auto;
  /* height: 600px; */
}
.custom-scroll {
  width: 10px;
  height: 622px;
  background: #c4c4c4;
  position: absolute;
  right: -23px;
  top: 108px;
  border-radius: 20px;
  max-height: 600px;
}

.custom-thum {
  width: 10px;
  min-height: 80px;
  background: #bb5876;
  border-radius: 20px;
}
.full-img-wrapper {
  position: relative;
}

/* .items-option.active, .items-option:hover{
   filter: brightness(0) saturate(100%) invert(82%) sepia(7%) saturate(1127%) hue-rotate(316deg) brightness(101%) contrast(98%);
} */
/* *online booking widget vouchers 1 End*=== */
.custo-scroll .full-img-larg-text::-webkit-scrollbar {
  background: red !important;
}
.custo-scroll .full-img-larg-text::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

/* @mediquery start */
@media only screen and (max-width: 1400px) {
  .full-img-larg-text {
    max-height: 410px;
  }
}
@media only screen and (max-width: 1200px) {
  .full-img-larg-text {
    max-height: 610px;
  }
}
@media only screen and (max-width: 991px) {
  .canva-wrapper {
    padding: 45px 0px 160px;
  }

  img.child-content-img {
    left: 8px;
    height: 96%;
  }
}

@media only screen and (max-width: 767px) {
  .canva-des {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }

  .canva-top-row {
    margin-bottom: 50px;
  }
  .canva-wrapper .swiper-wrapper {
    margin-bottom: 77px;
  }
  .canva-wrapper {
    padding: 45px 0px 52px;
  }

  img.child-content-img {
    left: 11px;
    height: 98%;
    width: 92%;
    top: 5px;
    border-radius: 40px;
  }

  .swiper-mob-main {
    border-radius: 24px;
  }

  .online-booking-content {
    padding: 30px 14px 34px;
  }

  .time-select {
    margin-left: 0;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 480px) {
  img.child-content-img {
    left: 5px;
    height: 97%;
    width: 93%;
    top: 5px;
    border-radius: 11px;
  }

  .full-img-larg-text {
    position: absolute;
    top: 88px;
    left: 0;
    right: 0;
    margin: 0;
    max-height: 326px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .full-inner-cover {
    max-width: 200px;
  }
}

.cancel-policy {
  background: #f9f6f4;
  padding: 22px 23px;
  border-radius: 5px;
}
