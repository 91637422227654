/* common css */
/* font-family: 'Inter', sans-serif;
font-family: 'Source Sans Pro', sans-serif;
font-family: 'Abhaya Libre', serif;
*/
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
      url('../font/SourceSansPro-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-LightItalic.woff2') format('woff2'),
      url('../font/SourceSansPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('../font/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-Regular.woff2') format('woff2'),
      url('../font/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-Black.woff2') format('woff2'),
      url('../font/SourceSansPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
      url('../font/SourceSansPro-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-BoldItalic.woff2') format('woff2'),
      url('../font/SourceSansPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-Italic.woff2') format('woff2'),
      url('../font/SourceSansPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-BlackItalic.woff2') format('woff2'),
      url('../font/SourceSansPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-Bold.woff2') format('woff2'),
      url('../font/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-ExtraLight.woff2') format('woff2'),
      url('../font/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/SourceSansPro-Light.woff2') format('woff2'),
      url('../font/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}





html {
  overflow-x: hidden;
}

body {
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;
  color: #374151;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 20px;
  line-height: 1.25;
  font-weight: 400;
}

h1,
.h1 {
  font-weight: normal;
  font-size: 38px;
  line-height: 1.1;
  color: #333;
}

h2,
.h2 {
  font-size: 30px;
  color: #333;
}

h3,
.h3 {
  font-size: 26px;
  color: #333;
}

h4,
.h4 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 16px;
}

.small {
  font-size: 13px !important;
}

p {
  margin-bottom: 30px;
  font-size: 14px;
  color: #374151;
}

p:nth-last-child(1) {
  margin-bottom: 0;
}

ul li,
ol li {
  font-size: 14px;
  color: #374151;
}

.text-justify {
  text-align: justify !important;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #fff;
}

::-moz-selection {
  color: #fff;
  background: #8c1c4d;
}

::selection {
  color: #fff;
  background: #8c1c4d;
}

.overflow-unset {
  overflow: unset !important;
}
img {
  max-width: 100%;
  width: auto;
}

.fw-semibold {
  font-weight: 600 !important;
}
.fw-regular {
  font-weight: 400 !important;
}
.f-18 {
  font-size: 18px;
}
.f-16 {
  font-size: 16px;
}
.text-jusitfy {
  text-align: justify;
}

.top-70 {
  top: 70px;
}

/*plaecholder*/

input.form-control::-webkit-input-placeholder,
textarea.form-control::-webkit-input-placeholder {
  color: #9ca3af;
  opacity: 1;
}

input.form-control::-moz-placeholder,
textarea.form-control::-moz-placeholder {
  color: #9ca3af;
  opacity: 1;
}

input.form-control:-ms-input-placeholder,
textarea.form-control:-ms-input-placeholder {
  color: #9ca3af;
  opacity: 1;
}

input.form-control::-ms-input-placeholder,
textarea.form-control::-ms-input-placeholder {
  color: #9ca3af;
  opacity: 1;
}

input.form-control::placeholder,
textarea.form-control::placeholder {
  color: #9ca3af;
  opacity: 1;
}

:focus {
  outline: 0;
}

.btn {
  font-weight: normal;
  font-size: 16px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 2px;
  line-height: normal;
  padding: 9px 13px;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn i {
  padding-right: 8px;
  font-weight: 600;
}

.btn-lg {
  font-size: 17px;
  padding: 13px 15px;
  border-radius: 4px;
}

.btn-sm {
  font-size: 12px !important;
  padding: 5px 8px !important;
  border-radius: 4px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #8c1c4d !important;
  border-color: #8c1c4d !important;
}

.btn-primary:hover {
  color: #8c1c4d !important;
  background-color: #fff !important;
  border-color: #8c1c4d !important;
}

.btn-secondary {
  color: #000 !important;
  background-color: #eeeeee !important;
  border-color: #eeeeee !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #8c1c4d !important;
  border-color: #9ca3af !important;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.btn-danger {
  color: #fff;
  background-color: #ef0000;
  border-color: #ef0000;
}

.btn-danger:hover {
  color: #ef0000 !important;
  background-color: #fff !important;
  border-color: #ef0000 !important;
}

.btn-preview {
  color: #3a3432;
  background-color: #fbd2c3;
  border-color: #fbd2c3;
}

.btn-preview:hover {
  background-color: #eeab92 !important;
  border-color: #eeab92 !important;
}

.btn-dark {
  color: #FFF;
  background-color: #374151;
  border: 1px solid #374151;
  /* box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  padding: 9px 15px;
  text-align: center;
  line-height: normal; */
}

.btn-dark:hover {
  background-color: transparent;
  color: #3a3432;
}

.btn.btn-outline-primary {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border: 1px solid #8c1c4d;
  color: #374151;
  padding: 9px 13px;
}

.btn.btn-outline-primary:hover {
  color: #fff;
  background-color: #8c1c4d;
  border-color: #8c1c4d;
}

.btn-checkout {
  color: #fff !important;
  background-color: #374151 !important;
  border-color: #374151 !important;
}

.btn-checkout:hover {
  color: #374151 !important;
  background-color: #fff !important;
  border-color: #374151 !important;
}

.btn-pay {
  color: #fff !important;
  background-color: #374151 !important;
  border-color: #374151 !important;
  font-weight: bold;
}

.btn-pay:hover {
  color: #fff !important;
  background-color: #8c1c4d !important;
  border-color: #8c1c4d !important;
}

.btn-pay-voucher {
  color: #fff !important;
  background-color: #8f807d !important;
  border-color: #8f807d !important;
  font-weight: bold;
}

.btn-pay-voucher:hover {
  color: #fff !important;
  background-color: #8c1c4d !important;
  border-color: #8c1c4d !important;
}

.btn-cancel {
  color: #3a3432 !important;
  background-color: #f4eeeb !important;
  border-color: #f4eeeb !important;
  padding: 9px 13px !important;
}

.btn-cancel:hover {
  color: #fff !important;
  background-color: #8c1c4d !important;
  border-color: #8c1c4d !important;
}

.btn-suvagrey {
  color: #fff !important;
  background-color: #8f807d !important;
  border-color: #8f807d !important;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 10px;
  text-decoration: none;
  min-height: 27px;
  min-width: 36px;
  text-align: center;
}

.btn-suvagrey:hover {
  color: #fff !important;
  background-color: #8c1c4d !important;
  border-color: #8c1c4d !important;
}

.btn-suvagrey img {
  max-width: 12px;
  display: block;
  filter: brightness(0) invert(1);
  height: 12px;
  margin: 1px auto;
}

.btn.btn-outline-grey {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border: 1px solid #e5e7eb;
  color: #374151;
  padding: 9px 13px;
}

.btn.btn-outline-grey:hover {
  color: #374151;
  background-color: #e5e7eb;
  border-color: #e5e7eb;
}

a {
  color: #0b0b0b;
  line-height: normal;
  background-color: transparent;
  display: inline-block;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #8c1c4d;
}

a:visited,
a:focus,
a:active {
  text-decoration: none;
  outline: 0;
}

.container {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.container.full {
  max-width: 100%;
  padding-left: 110px;
  padding-right: 110px;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

body .color-wine {
  color: #8c1c4d;
}

.bg-pink {
  background-color: #f4eeeb !important;
}
.bg-warning {
  background-color: #fbd2c3 !important;
}
.btn.btn-outline {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border: 1px solid #e5e7eb;
  color: #374151;
}

.btn.btn-outline:hover {
  color: #fff;
  background-color: #8c1c4d;
  border-color: #8c1c4d;
}

.btn-sm {
  font-size: 12px !important;
  padding: 5px 8px !important;
  border-radius: 4px !important;
}

.alert {
  background-color: #fffbd7;
  border-radius: 4px;
  padding: 20px;
  font-size: 15px;
  line-height: normal;
}

.alert a {
  text-decoration: underline;
  color: #8c1c4d;
  font-size: 15px;
  line-height: normal;
  vertical-align: baseline;
}

.info {
  color: #3b82f6;
}

label {
  font-weight: 500;
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 8px;
}

form label {
  display: block;
}

input.form-control,
select.form-control,
textarea.form-control {
  line-height: 1.3;
  font-size: 14px;
  color: #374151;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 8px;
}

.form-control.lg {
  padding: 17px;
}

textarea.form-control {
  resize: none;
  font-family: "Source Sans Pro", sans-serif;
}

.form-control:hover,
.dropdown-toggle:hover {
  border-color: #9ca3af;
  box-shadow: 0 0 0 3px #e5e7eb;
}

.form-control:focus,
.dropdown-toggle:focus {
  border-color: #5048e5;
  box-shadow: 0 0 0 3px #e5e7eb;
}

select.form-control {
  background-image: url(../images/drodown-icon.svg) !important;
  background-repeat: no-repeat;
  background-position: 97% center;
  padding-right: 30px;
}

input.date {
  background-image: url(../images/date.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 97% center !important;
  padding-right: 30px !important;
}

.rmdp-container {
  display: block !important;
}

input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-switch .form-check-input:focus {
  background-image: url(../images/switch-btn.png);
}

.form-check {
  min-height: auto;
  margin-bottom: 10px;
  padding-left: 43px;
}

.form-switch .form-check-input {
  border: 0;
  background-color: #d1d5db;
  background-image: url(../images/switch-btn.png);
  width: 32px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 2px center;
  margin-top: 0;
  margin-left: -43px;
}

.form-switch .form-check-input:checked {
  background-color: #8c1c4d;
  background-position: 90% center;
  background-image: url(../images/switch-btn.png);
}

.form-switch span {
  line-height: normal;
}

input.input-photo {
  font-size: 0;
  border: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  cursor: pointer;
}

.input-file span,
.input-file-square span {
  color: #8c1c4d;
  font-size: 13px;
  width: 100%;
  margin: 8px 0;
}

.input-file span.remove-img {
  color: #8c1c4d;
  position: absolute;
  text-align: right;
  top: -17px;
  right: 0px;
  font-size: 25px;
}

.input-file span.remove-img {
  top: -27px;
  right: -20px;
}

.input-file {
  background-color: #f4eeeb;
  height: 160px;
  width: 160px;
  text-align: center;
  border-radius: 100%;
}

.checkbox input[type="checkbox"] {
  appearance: none;
  position: absolute;
  cursor: pointer;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.checkbox {
  position: relative;
}

.checkbox label {
  font-size: 18px;
  color: #374151;
  font-weight: normal;
  margin-bottom: 0;
  line-height: 1.2;
  position: relative;
  padding-left: 30px;
}

.checkbox label:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  border: 1px solid #d1d5db;
  background-image: url(../images/check.png);
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox input[type="checkbox"]:checked + label:before {
  background-color: #8c1c4d;
  border-color: #8c1c4d;
}

.user-initial {
  background-color: #5048e5;
  height: 32px;
  width: 32px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 32px;
  font-weight: 600;
  border-radius: 100%;
}

.dropdown-box ul li a {
  padding: 8px;
  border-radius: 2px;
  text-decoration: none;
  font-size: 14px;
  color: #374151;
}

.dropdown-box ul li a:hover {
  background-color: #f9fafb;
  color: #374151;
}

.dropdown-box ul li a:focus,
.dropdown-box ul li a.select {
  background-color: #f0f3ff;
}

.dropdown-box span {
  font-size: 12px;
  display: block;
  line-height: 16px;
}

.dropdown-box .user-name {
  color: #374151;
}

.dropdown-box .user-id {
  color: #6b7280;
}

.dropdown-box {
  box-shadow: 0px 2px 4px rgb(31 41 55 / 6%), 0px 4px 6px rgb(31 41 55 / 10%);
  border-radius: 4px;
  background-color: #fff;
  padding: 8px;
  width: 100%;
  border: 0;
  z-index: 9;
}

.dropdown-toggle {
  border-radius: 4px;
  /* padding: 8px 25px 8px 8px; */
  border: 1px solid #e0e0e0;
  background-color: #fff;
  font-size: 16px;
  color: #374151;
  text-align: left;
  position: relative;
}

.dropdown-toggle::after {
  border: 0;
  background-image: url(../images/drodown-icon.svg);
  position: absolute;
  right: 8px;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: top right;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown-toggle-icon-none::after {
  display: none;
}

.page-content {
  width: calc(100% - 70px);
  margin-left: 70px;
  padding-top: 71px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.error label {
  color: #ef4444;
}

.error .form-control {
  border-color: #e11d48;
  box-shadow: 0 0 0 3px rgb(225 29 72 / 20%);
}

.error-txt {
  font-size: 14px;
  color: #ef4444;
  position: relative;
  display: block;
  margin: 8px 0;
}

.error-txt:before {
  content: "\f071";
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
}

.focus label {
  color: #5048e5;
}

.focus .form-control {
  border-color: #5048e5;
  box-shadow: 0 0 0 3px rgb(80 72 229 / 20%);
}

.focus-txt {
  font-size: 14px;
  color: #5048e5;
  position: relative;
  display: block;
  margin: 8px 0;
}

.focus-txt:before {
  content: "\f06a";
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
}

.success label {
  color: #0d9488;
}

.success .form-control {
  border-color: #0d9488;
  box-shadow: 0 0 0 3px rgb(80 72 229 / 20%);
}

.success-txt {
  font-size: 14px;
  color: #0d9488;
  position: relative;
  display: block;
  margin: 8px 0;
}

.success-txt:before {
  content: "\f00c";
  font-size: 14px;
  padding-right: 10px;
}

.custom-tab {
  display: inline-block;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  background-color: #eee;
  padding: 2px;
}

.custom-tab .list-group-item {
  display: inline-block;
  width: auto;
  font-size: 16px;
  padding: 7px 13px;
  line-height: normal;
  border-radius: 4px;
  border: 0;
  background-color: transparent;
}

.custom-tab .list-group-item.active {
  background-color: #fff;
  color: #8c1c4d;
}

.table > :not(caption) > * > * {
  padding: 20px;
}

.table {
  color: #374151;
}

.table thead th {
  font-weight: 600;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  font-size: 16px;
  line-height: normal;
}

.badge {
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 16px;
  line-height: 1;
  font-weight: normal;
}

.badge.paid {
  background-color: #ccfbf1;
  color: #0d9488;
}

.accordion-item {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  border: 1px solid #d4d4d4 !important;
  overflow: hidden;
}

.accordion-button {
  font-size: 18px;
  font-weight: 600;
  padding: 24px 30px;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #cacaca;
}

.accordion-body ul li {
  padding: 15px 30px;
  border-bottom: 0.75px solid #cacaca;
  font-size: 18px;
}

.accordion-button:not(.collapsed) {
  color: #374151;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
}

.accordion-body ul li label {
  color: inherit;
  font-size: inherit;
}

.accordion-button::after {
  background-position: center right;
}

/* end base css ======================== */

/* loader css */

#preloader {
  position: fixed;
  z-index: 99999;
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#preloader #status {
  position: relative;
  z-index: 1;
}

/* side panel */

.sidenav-bar {
  width: 70px;
  position: fixed;
  height: 100%;
  left: 0;
  background: linear-gradient(180deg, #8f807d 0%, #393332 100%);
  z-index: 1048;
}
.sidemenu {
  overflow-y: scroll;
  height: calc(100vh - 81px);
}

.sidenav-bar {
  width: 70px;
  position: fixed;
  height: 100%;
  left: 0;
  background: linear-gradient(180deg, #8f807d 0%, #393332 100%);
  z-index: 1048;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -ms-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out;
}

.sidemenu::-webkit-scrollbar-track {
  background-color: #fff;
}

.sidemenu::-webkit-scrollbar {
  width: 2px;
}

.sidemenu::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.side-menu-wrapper::-webkit-scrollbar-track {
  background-color: #fff;
}

.side-menu-wrapper::-webkit-scrollbar {
  width: 2px;
}

.side-menu-wrapper::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.sidenav-bar .sidemenu > ul > li {
  padding: 5px 10px;
}

.sidenav-bar .sidemenu > ul > li a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidenav-bar .sidemenu > ul > li a .icon {
  height: 47px;
  width: 47px;
  border-radius: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidenav-bar .sidemenu > ul > li a.active .icon,
.sidenav-bar .sidemenu > ul > li a:hover .icon {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidenav-bar .sidemenu > ul > li a.active {
  font-weight: bold;
}

@media (min-width: 768px) {
    .fc-day {
        min-width: calc(100% / 5);
    }
}

/* Optional: Adjust for larger screens */
@media (min-width: 1024px) {
    .fc-day {
        min-width: calc(100% / 7); /* Or another value for larger screens */
    }
}

@media only screen and (max-width: 567px) {
  .sidemenu {
    /* height: auto; */
  }
  .sidenav-bar {
    width: calc(100vw - 80px);
    /* height: auto; */
  }
  .sidenav-bar .sidemenu > ul > li {
    padding: 0px 10px;
  }
  /* .sidenav-bar .sidemenu > ul > li a .icon {
    height:35px;
    width: 35px;
  } */
}

/* header css */

header {
  position: fixed;
  top: 0;
  width: calc(100% - 70px);
  left: 70px;
  border-bottom: 1px solid #eee;
  z-index: 9;
  background-color: #fff;
}
.custom-header-padding-with-ad{
  padding: 15px 20px;
}

header .user-profile-icon .user-status {
  position: absolute;
  right: 0;
  box-shadow: 0px 0px 0px 2px #ffffff;
  height: 7.5px;
  width: 7.5px;
  background-color: #d4d4d8;
  border-radius: 100%;
  bottom: 0;
}

header .user-profile-icon .user-status.online {
  background-color: #4ade80;
}

header .user-profile-icon .user-status.offline {
  background-color: #f43f5e;
}

header .search .search-wrapper form {
  width: 280px;
}

.search .input-group .input-group-text,
.search .input-group .close {
  background-color: transparent;
  border: 0;
  padding: 0;
  line-height: 0;
  z-index: 4;
}

.search {
  position: relative;
}

.search .input-group .input-group-text {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.search .input-group .close {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.search .input-group .search-input {
  overflow: hidden;
  padding: 8px 35px;
  width: 100%;
  border-radius: 4px !important;
}

.search .input-group i {
  color: #9ca3af;
}

.search .search-result {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.search .search-result.open {
  opacity: 1;
  visibility: visible;
}

.search .search-result .user-img img {
  max-width: 32px;
}

.search .search-wrapper {
  /* position: absolute; */
  right: calc(100% - 22px);
  top: 0;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  opacity: 0;
  visibility: hidden;
}

header .search .search-wrapper.open {
  visibility: visible;
  opacity: 1;
}

/* calendar css */

.print-img {
  border: 0;
  box-shadow: none;
  padding: 10px;
}

.print-img img:hover {
  filter: brightness(0) invert(1);
}

.calendar-header .staff-dropdown {
  width: 220px;
  display: inline-block;
  vertical-align: middle;
}

.calendar-header .staff-dropdown .user-img img {
  max-width: "100%";
  object-fit: cover;
}

.calendar-header .create-dropdown .dropdown-menu {
  min-width: 200px;
}

.calendar-header .custom-tab .list-group-item {
  min-width: 100px;
  text-align: center;
}

.date .icon {
  color: #d4d4d4;
  padding: 6px 15px;
}

/* .date .day {
  font-size: 16px;
  color: #999999;
  padding: 6px 22px;
} */

.date .input-group-text {
  background-color: transparent;
  border-color: #d4d4d4;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: capitalize;
  border-radius: 6px;
}

.date input {
  box-shadow: none;
  border-color: #d4d4d4;
  font-size: 16px;
  color: #8c1c4d;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
}

/* drawer css */

.drawer {
  position: fixed;
  right: -100%;
  top: 0;
  height: 100%;
  box-shadow: 0px 0px 14px rgb(0 0 0 / 25%);
  background-color: #fff;
  padding: 30px 40px;
  width: 550px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  overflow: hidden;
  overflow-y: auto;
}

.drawer.open {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.close-drawer {
  position: absolute;
  right: -3px;
  top: 0;
}

.drawer .drawer-body {
  overflow-y: auto;
  height: calc(100vh - 120px);
  margin: 0 -3px;
  overflow-x: hidden;
  padding: 0 3px;
}

.drawer .include-footer .drawer-body {
  height: calc(100vh - 210px);
}

#addappoinment-drawer .drawer-body {
  height: calc(100vh - 270px) !important;
}

.drawer-body::-webkit-scrollbar-track,
.content-wrp::-webkit-scrollbar-track {
  background-color: #fff;
}

.drawer-body::-webkit-scrollbar,
.content-wrp::-webkit-scrollbar {
  width: 2px;
}

.drawer-body::-webkit-scrollbar-thumb,
.content-wrp::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
}

.drawer-footer {
  padding-top: 35px;
  position: relative;
}

.drawer-footer:after {
  content: "";
  position: absolute;
  left: -40px;
  top: 0;
  right: -40px;
  width: calc(100% + 80px);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
}

.full-screen-drawer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  box-shadow: 0px 0px 14px rgb(0 0 0 / 25%);
  background-color: #fff;
  width: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  overflow: hidden;
}

.full-screen-drawer.open {
  opacity: 1;
  visibility: visible;
}

.full-screen-drawer .drawer-header {
  border-bottom: 1px solid #eee;
}
.full-screen-drawer .drawer-header h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #333333;
}

.full-screen-drawer .drawer-body h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #333333;
}

.full-screen-drawer .close-drawer {
  right: 25px;
  top: 24px;
}

.full-screen-drawer-footer {
  border-top: 1px solid #eee;
}

.full-screen-drawer .right-col {
  border-left: 1px solid #eee;
}

.full-screen-drawer .drawer-body {
  height: calc(100vh - 74px);
  overflow-y: auto;
}

.full-screen-drawer .drawer-body > .row {
  height: 100%;
}

.user-box a {
  text-decoration: none;
}

.user-box .user-initial {
  color: #8c1c4d;
  font-weight: 300;
  font-size: 30px;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #f4eeeb;
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.user-box span {
  color: #999999;
}

.complete-box {
  height: 450px;
  width: 450px;
  margin: 0 auto;
  border-radius: 100%;
  background-color: #f9f6f4;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.full-screen-drawer .right-col .btn-lg {
  font-size: 20px;
  font-weight: 600;
  padding: 20px 15px;
}

.drawer-body .left-col {
  background-color: #fafafa;
}

.product-box,
.newsale-probox .membership-box {
  padding: 25px 50px 25px 25px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  position: relative;
  margin-top: 30px;
  cursor: auto;
}

.product-box .close,
.newsale-probox .membership-box .close {
  position: absolute;
  color: #ef0000;
  right: 15px;
  top: 15px;
  line-height: 1;
  font-size: 16px;
}

.product-box.open .close,
.newsale-probox .membership-box.open .close {
  visibility: visible;
}

.product-box .product-header p,
.newsale-probox .membership-box .product-header p {
  font-size: 18px;
}

.product-box .card,
.newsale-probox .membership-box .card {
  border: 0;
  padding: 10px 0 0;
}

.product-box form input {
  width: 100%;
  box-shadow: none;
}

.product-box form .input-group span {
  font-size: 16px;
  color: #000;
  border-radius: 5px !important;
  background-color: #d4d4d4;
  display: inline-block;
  padding: 5px 5px;
  min-width: 38px;
  text-align: center;
}

.product-box form .input-group span.disabled {
  background: rgba(238, 238, 238, 0.5);
  color: #999999;
  pointer-events: none;
}

.product-box form .qty {
  width: 67px;
}

.product-box form .price {
  width: 130px;
}

.product-box form .discount {
  width: 230px;
}

.product-box form .discount input {
  min-width: 120px;
  flex: none;
  border-radius: 4px !important;
}

.full-screen-drawer-footer .total {
  border-bottom: 1px solid #eee;
}

/* sales page */

.sales-list .sales-list-header input.date {
  min-width: 440px;
  background-size: 20px;
}

.sales-list .sales-list-header .form-control {
  min-height: 40px;
}

.sales-list table {
  border-top: 1px solid #eee;
  border-color: #eee;
}

.sales-list-header .search {
  width: 325px;
}

.sales-list table thead th {
  border-color: #eee !important;
}

#sale-complete-link {
  cursor: pointer;
}

.sale-comlete-data li.list-group-item {
  padding: 1rem 1rem;
}

.sale-comlete-data li.total {
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
}

.sale-comlete-data li label {
  font-weight: 600;
  color: #374151;
  margin: 0;
  display: block;
  font-size: 18px;
}

.sale-comlete-data li p {
  font-size: 18px;
  color: #999;
}

.sale-comlete-data li span {
  font-size: 16px;
  color: #999;
}

#salecomplete-drawer .btn-dark {
  min-width: 140px;
}

.left-col .search .search-input {
  border-color: #fff;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 0%);
}

.left-col .search .search-input:focus,
.left-col .search .search-input:hover {
  border-color: #5048e5;
  box-shadow: 0 0 0 3px #e5e7eb;
}

.search.large-input .input-group .search-input {
  padding: 13px 30px 13px 60px;
  font-size: 23px;
  font-weight: 300;
  font-family: "Source Sans Pro", sans-serif;
  color: #999;
}

.search.large-input .input-group .input-group-text {
  left: 33px;
}

.right-col .search-result {
  border: 0;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  /* height: calc(100vh - 100px); */
  overflow-y: auto;
}

.right-col .search-result li a {
  border-bottom: 1px solid #eee;
  padding: 12px 33px;
}

.right-col .search-result li span {
  font-size: 14px;
  color: #999;
  font-family: "Source Sans Pro", sans-serif;
}

.user-id .close {
  color: #000;
  vertical-align: top;
  font-size: 17px;
}

#newsale-drawer .add-item-panel,
#newsale-drawer .add-item-panel > div {
  height: 100%;
}

/* mega menu */

.mega-menu {
  background-color: #fff;
}

.mega-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #eee;
}

.mega-menu ul li.children > ul {
  position: absolute;
  left: -100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  top: 0;
  z-index: 1;
  background-color: #fff;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  margin-top: -1px;
}

.mega-menu ul li.children ul.open {
  opacity: 1;
  visibility: visible;
  left: 0;
}

.menu-back {
  position: absolute;
  right: 20px;
  top: -40px;
  background-image: url(../images/right-arrow.png);
  background-repeat: no-repeat;
  height: 22px;
  width: 28px;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}

.mega-menu ul li.childrenopen > .menu-back {
  opacity: 1;
  visibility: visible;
}

.mega-menu .title {
  font-size: 18px;
  padding: 14px 24px;
  width: 100%;
  border-top: 1px solid #eee;
}

.mega-menu ul li a {
  font-size: 18px;
  text-decoration: none;
  color: #374151;
  text-transform: capitalize;
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
  display: block;
  position: relative;
  background-color: #fff;
}

.mega-menu ul li a i {
  position: absolute;
  right: 18px;
  font-size: 20px;
  font-weight: bold;
  top: 50%;
  transform: translateY(-50%);
}

.mega-menu ul li a span {
  display: block;
  float: right;
}

/* Vouchers page */

.voucher-avilable {
  width: 30%;
  padding: 30px 40px;
  border-right: 1px solid #eee;
}

.voucher-form {
  width: 30%;
  padding: 30px 40px;
  overflow: hidden;
}

.voucher-preview {
  background-color: #fafafa;
}

.voucher-avilable .complete-box {
  height: 400px;
  width: 400px;
}

.voucher-preview-inner {
  padding: 30px 40px;
}

.voucher-form label,
.voucher-form .form-switch span {
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
}

.voucher-form p,
p.input-para-conent {
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  padding: 20px;
  font-size: 13px;
}

.voucher-form select {
  color: #6b7280;
}

.voucher-preview-inner h1 {
  font-size: 50px;
  color: #000000;
  font-weight: bold;
}

.voucher-preview-inner h2 {
  color: #000000;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
}

.voucher-preview-inner p {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.voucher-preview-inner label {
  color: #374151;
  line-height: normal;
  margin-bottom: 5px;
}

.voucher-preview-inner .h4 {
  font-weight: 600;
}

.voucher-preview-inner .desc p {
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Source Sans Pro", sans-serif;
}

.voucher-preview-inner .desc p:last-child {
  margin-bottom: 0;
}

.voucher-action .edit,
.voucher-action .sell,
.voucher-action .edit,
.voucher-action .delete {
  background-color: #8f807d;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 10px;
  text-decoration: none;
  color: #fff;
}

.voucher-action .delete i {
  font-size: 12px !important;
}

.voucher-grid.box-image-cover .user-initial {
  font-size: 22px;
  font-weight: bold;
}

.voucher-action .edit:hover,
.voucher-action .sell:hover,
.voucher-action .delete:hover {
  background-color: #828df8;
}

/* Membership  css */

.membership-action .edit,
.membership-action .sell,
.membership-action .edit,
.membership-action .delete {
  background-color: #8f807d;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 10px;
  text-decoration: none;
  color: #fff;
}

.membership-action .delete i {
  font-size: 12px !important;
}

.membership-grid.box-image-cover .user-initial {
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  text-transform: capitalize !important;
  padding: 16px;
}

.membership-action .edit:hover,
.membership-action .sell:hover,
.membership-action .delete:hover {
  background-color: #828df8;
}

/* start staff css */

.common-tab .nav {
  border-bottom-color: #eee;
}

.common-tab .nav-tabs li a {
  border: 0;
  border-bottom: 3px solid transparent;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #374151;
  text-decoration: none;
  padding: 16px 50px;
}

.common-tab .nav-tabs li a.active {
  border-bottom: 3px solid #bb5876;
  font-weight: 600;
  color: #8c1c4d;
}
.common-tab .nav-tabs.payment-nav-item li a {
  border: 0;
  border-bottom: 3px solid transparent;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  color: #374151;
  text-decoration: none;
  padding: 12px 25px;
  border-radius: 0px;
}

.common-tab .nav-tabs li a.active-payments {
  border-bottom: 3px solid #FFC1B0;
  font-weight: 600;
  color: #8c1c4d;
  background: #FCF7F8;
}

.box-image-cover {
  position: relative;
  width: calc(16.66% - 30px);
  padding: 35px 26px;
  margin: 0 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  text-align: center;
  margin-bottom: 30px;
  text-decoration: none;
  color: #374151;
}

.box-image-cover .image-content {
  padding-top: 20px;
}

.box-image-cover .image-content i {
  line-height: 1.4;
  font-size: 14px;
}

.box-image-cover .image-content h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.box-image-cover.add-price-popup {
  cursor: pointer;
}

.box-image-cover .user-initial {
  font-weight: 300;
  color: #8c1c4d;
  text-transform: uppercase;
  background-color: #f9f6f4;
  font-size: 30px;
  height: 118px;
  width: 118px;
  line-height: 118px;
}

.box-image-cover .close-category {
  position: absolute;
  top: 15px;
  right: 15px;
}

.box-image-cover .close-category img {
  max-width: 15px;
}

#roster table .hide {
  display: none;
}

#roster table .show {
  display: block;
}

#roster table td {
  vertical-align: middle;
  border: 1px solid #eee;
}

#roster table th {
  vertical-align: middle;
  border: 1px solid #eee;
}

#roster table thead tr th {
  border-top-color: #d4d4d4;
}

#roster table tbody tr:last-child td {
  border-bottom-color: #d4d4d4;
}

#roster table tbody tr td:last-child,
#roster table thead tr th:last-child {
  border-right-color: #d4d4d4;
}

#roster table tbody tr td:first-child,
#roster table thead tr th:first-child {
  border-left-color: #d4d4d4;
}

#roster table td:last-child .addtime-popup,
#roster table td:last-child .updatetime-popup {
  right: 0;
  left: auto;
}

#roster .addtime-popup,
#roster .updatetime-popup {
  position: absolute;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  z-index: 1;
  width: 370px;
  top: 134%;
  left: 0;
}

#roster .addtime-popup .close,
#roster .updatetime-popup .close {
  position: absolute;
  top: 15px;
  right: 15px;
  max-width: 15px;
}

#roster .addtime-popup .btn,
#roster .updatetime-popup .btn {
  padding: 7px 13px;
}

#roster table {
  table-layout: fixed;
  margin-bottom: 105px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border: 1px solid #d4d4d4;
}

#roster table .updated-time {
  color: #374151;
  text-decoration: none;
  font-size: inherit;
}

#roster .updatetime-popup .popup-footer button,
#roster .addtime-popup .popup-footer button {
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: #374151;
  padding: 17px 15px;
  background: transparent;
  border: 1px;
}

#roster .updatetime-popup .popup-footer, #roster .addtime-popup .popup-footer{
  border-top: 1px solid #cacaca;
}

#roster .updatetime-popup .popup-footer button:not(:last-child), #roster .addtime-popup .popup-footer button:not(:last-child) {
  border-right: 1px solid #cacaca;
}

.away-text {
  color: #fff;
  text-decoration: none;
  font-size: inherit;
  text-transform: uppercase;
}

#removetimemodal h6 {
  color: #6b7280;
  margin-bottom: 0;
}

#removetimemodal .modal-content {
  border-radius: 8px;
  filter: drop-shadow(0px 15px 50px rgba(31, 41, 55, 0.25));
  border: 0;
}

#removetimemodal .modal-dialog {
  max-width: 430px;
}

/* .modal {
  background-color: rgb(255 255 255 / 90%);
} */

.modal .close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  z-index: 1;
  max-width: 15px;
}

.addstaff-member .working-hrs {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.addstaff-member .detail,
.addstaff-member .working-hrs,
.addstaff-member .service {
  padding: 25px 35px;
  overflow: hidden;
}

.subtitle {
  color: #999;
}

.addstaff-member .service ul {
  margin-top: 24px;
}

.addstaff-member .service ul .li {
  position: relative;
  margin-top: -1px;
}

.addstaff-member .serviceul .li:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -200px;
  width: calc(100% + 400px);
  height: 1px;
  background-color: #eee;
  right: -200px;
}

.addstaff-member .service ul .li:before {
  content: "";
  position: absolute;
  top: 0;
  left: -200px;
  width: calc(100% + 400px);
  height: 1px;
  background-color: #eee;
  right: -200px;
}

.addstaff-member .service ul ul .li:last-child {
  padding-bottom: 0;
}

.addstaff-member .service ul ul .li:last-child:after {
  display: none;
}

.addstaff-member .working-hrs label {
  color: #999;
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0 5px 0 0;
  min-width: 115px;
}
.addstaff-member .working-hrs .input {
  min-width: 115px;
}

.addstaff-member .working-hrs span {
  font-size: 18px;
}

.addstaff-member .working-hrs .li {
  display: flex;
  align-items: center;
  padding: 18px 0;
  flex-wrap: wrap;
}

.addstaff-member .service .li {
  padding: 25px 0 24px;
}

.addstaff-member.full-screen-drawer .drawer-body {
  height: calc(100vh - 190px);
}

.close-breaktime img {
  max-width: 15px;
}

.addstaff-member .drawer-footer .btn {
  min-width: 388px;
}

.addstaff-member .drawer-footer .btn-secondary:hover img {
  filter: brightness(0) invert(1);
}

.user-upload-img img {
  max-width: 170px;
  border-radius: 100%;
}

/* & staff css */

/* salevoucher  css*/

.salevoucher-tab {
  border-top: 1px solid #eee;
}

.salevoucher-tab .nav-tabs li a {
  font-size: 18px;
}

.salevoucher-tab .voucher-box,
.salevoucher-tab .membership-box,
.salevoucher-tab .subscription-box {
  border: 1px solid #d4d4d4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 30px 15px;
  text-decoration: none;
  color: #374151;
  height: 100%;
  background-color: #fff;
}

.salevoucher-tab .voucher-box h5,
.salevoucher-tab .membership-box h5,
.salevoucher-tab .subscription-box h5 {
  font-size: 18px;
}

#product .table-responsive {
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
}

/* service page */

.service .common-tab .nav-tabs li a.active {
  color: #8c1c4d;
  border-color: #8c1c4d;
}

.service .complete-box a {
  color: #8c1c4d;
  font-size: inherit;
}

.service.page-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.drawer-header .btn {
  font-weight: 700;
  min-width: 114px;
}

.service .drawer-header .close.btn {
  background-color: #f4eeeb;
  border-color: #f4eeeb;
  color: #3a3432;
}

.service .drawer-header .close.btn:hover {
  border-color: #8c1c4d;
  background-color: #8c1c4d;
  color: #fff;
}

.add-form p {
  font-size: 16px;
  color: #999;
  line-height: 1.2;
}

.add-form form > .row {
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.add-form form > .row:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.add-form form label {
  color: #3a3432;
  font-size: 16px;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

.setting-dropdown .dropdown-toggle {
  border: 0;
  text-align: center;
  height: 40px;
  width: 40px;
  font-size: 18px;
  line-height: 40px;
  padding: 0;
  box-shadow: none;
  border-radius: 100%;
  vertical-align: middle;
  background-color: transparent;
}

.setting-dropdown .dropdown-toggle:hover,
.setting-dropdown .dropdown-toggle.show {
  background-color: #f2f3f4;
}

.setting-dropdown .dropdown-box ul li a {
  font-size: 13px;
  font-family: "Source Sans Pro", sans-serif;
}

.setting-dropdown .dropdown-box {
  border: 1px solid #a2a7ae;
}

.setting-dropdown li a img {
  max-width: 15px;
}

.search-radius.search .input-group .search-input {
  border-color: #fff;
  background-color: #f6f6f6;
  border-radius: 40px !important;
  box-shadow: 0 0 0 3px #ffffff;
  font-size: 16px;
  padding: 9px 45px;
}

.search-radius.search .input-group .input-group-text {
  left: 18px;
}

.table-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid #d4d4d4;
}

.services-table thead.thead-dark th,
tbody td {
  text-align: inherit;
  vertical-align: middle;
}

.services-table td:first-child {
  padding-left: 60px;
}

.services-table th:first-child {
  padding-left: 25px;
  position: relative;
}

.services-table td {
  position: relative;
  vertical-align: middle;
}

.services-table td {
  padding: 8px;
}

.services-table th {
  padding: 17px 8px;
}

.table-shadow .table {
  margin-bottom: 0;
}

.services-name {
  position: relative;
  padding-right: 10px;
}

.services-table-data tr:nth-of-type(even) {
  background: none !important;
}

.services-table-data tr:nth-of-type(odd) {
  --bs-table-accent-bg: inherit !important;
}

.services-table-data tr:first-child {
  position: relative;
  border-top: 2px solid #eeeeee;
}

.services-table-data thead,
.services-table-data tbody,
.services-table-data tfoot,
.services-table-data tr,
.services-table-data td,
.services-table-data th {
  position: relative;
  border: 0px;
}

.services-table-data tr.border-top-bottom {
  border: 1px solid #8c1c4d;
}

.client-table-data td {
  padding: 0.5rem 0.5rem;
}

.client-table-data td:first-child {
  padding-left: 2rem;
  padding-right: 1rem !important;
}

/* .services-table td:first-child:before {
  content: "\f0c9";
  position: absolute;
  left: 24px;
  color: #cacaca;
  font-size: 16px;
  line-height: 1.3;
} */
.services-table td:first-child i.fas.fa-bars {
  color: #cacaca;
  font-size: 16px;
  line-height: 1.3;
}
.drag {
  cursor: grab;
}
.dragable {
  cursor: move;
}

.down-up-arrow {
  width: 16px;
  height: 20px;
  vertical-align: middle;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}

.down-up-arrow i {
  color: #9ca3af;
  position: absolute;
  left: 0;
  font-weight: 400;
}

.down-up-arrow .fa-angle-up {
  top: -1px;
}

.down-up-arrow .fa-angle-down {
  bottom: -1px;
}

.services-table-data .dropdown-box {
  width: 115px;
}

#add-category form label {
  color: #3a3432;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

#add-category .modal-content {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid #d4d4d4;
}

/* service list page  */

.service.list-page table tr td {
  font-size: 18px;
  padding: 15px 30px 15px 15px;
}

.service.list-page table tr td:first-child {
  padding-left: 60px;
}

.service.list-page td:first-child:before {
  line-height: 1.4;
}

/* new product */

#addproduct-drawer .input-file,
#addsuppliers-drawer .input-file,
#editproduct-drawer .input-file,
#editsuppliers-drawer .input-file {
  width: 200px;
  height: 235.29px;
  border-radius: 5px;
}

table .pro-img {
  max-width: 100px;
}

table .pro-title {
  width: calc(100% - 70px);
}

.box-image-cover .setting-dropdown {
  position: absolute;
  right: 10px;
  top: 10px;
}

.service.list-page .add-new-btn {
  min-width: 164px;
  position: relative;
}

.service.list-page .add-new-btn:before {
  content: "\f067";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/* client page  */

.list-view-tab li a {
  padding: 0 7px;
}

.list-view-tab li a img {
  opacity: 0.2;
}

.list-view-tab li a.active img {
  opacity: 1;
}

.list-view-lable {
  color: #000;
}

.list-view-content {
  padding: 35px 32px;
}

/* CLIENT DETAILS SLIDE OUT */

.client-detaildrawer {
  width: 845px;
}

.client-detaildrawer .close-drawer {
  right: 25px;
  top: 35px;
  z-index: 1;
}

.client-detaildrawer > .drawer-wrp,
.client-detaildrawer > .drawer-wrp > .drawer-body {
  height: 100%;
}

.client-detaildrawer .left-menu {
  padding: 30px 25px;
  border-right: 1px solid #d4d4d4;
}

.client-detaildrawer .content {
  padding: 30px 25px;
}

.action-box a span {
  color: #000000;
  margin: 7px 0 0;
}

.action-box a img {
  max-width: 30px;
}

.client-detaildrawer .nav-link {
  font-size: 16px;
  color: #374151;
  text-align: left;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  display: block;
  text-transform: capitalize;
}

.client-detaildrawer .nav-link.active,
.client-detaildrawer .show > .nav-link,
.client-detaildrawer .nav-link:hover {
  color: #374151;
  background-color: #f9f6f4;
}

.client-detaildrawer .event-box,
.drawer .event-box {
  display: block;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #d4d4d4;
  margin-bottom: 15px;
  color: #374151;
}

.client-editappoinment .footer-top {
  padding: 10px 0;
  position: relative;
}

.client-editappoinment .footer-top:after {
  content: "";
  position: absolute;
  left: -40px;
  bottom: 0;
  right: -40px;
  width: calc(100% + 80px);
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
}

.client-editappoinment#addappoinment-drawer .drawer-body {
  height: calc(100vh - 335px);
}

.client-editappoinment .cancel {
  background-color: #ef0000;
  border-color: #ef0000;
}

.client-editappoinment .cancel:hover {
  color: #ef0000;
  background-color: transparent;
}

.client-detaildrawer .user-initial {
  background-color: #f4eeeb;
  color: #8c1c4d;
  font-weight: 300;
  font-size: 30px;
  font-family: "Source Sans Pro", sans-serif;
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.client-detaildrawer .content-wrp {
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.client-detaildrawer .sell-gift-voucher {
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  padding: 5px 8px;
}

.client-detaildrawer .sell-gift-voucher:hover {
  background-color: #828df8;
  border-color: #828df8;
}

.client-detaildrawer span.active {
  font-weight: 600;
  font-size: 14px;
  color: #0d9488;
  border-radius: 4px;
  background-color: #ccfbf1;
  padding: 2px 6px;
  line-height: 16px;
}

.client-detaildrawer .event-box b,
.drawer .event-box b {
  font-weight: 600;
}

.client-detaildrawer .complete-box a {
  color: inherit;
  font-size: inherit;
}

.remove {
  color: #f43f5e;
  font-size: 12px;
  text-decoration: none;
}

.addphoto-drawer .img-wrap {
  margin-bottom: 10px;
  position: relative;
  padding-top: 100%;
}

.addphoto-drawer .img-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.addphoto-drawer .btn.disabled {
  background-color: #f3f4f6;
  color: #9ca3af;
  border-color: #f3f4f6;
  pointer-events: none;
}

.event-box p {
  font-size: 14px;
  line-height: 20px;
}

#listview table .user-initial {
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 18px;
  color: #8c1c4d;
  background-color: #f9f6f4;
}

#listview table .user {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

#listview table .user img {
  width: 100%;
}

#listview table a {
  color: inherit;
}

#listview table th,
#listview table td {
  border-color: #eee !important;
}

#listview table tbody tr:last-child td {
  border-bottom: 0;
}

#listview .table-responsive {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  margin: 0 auto;
  max-width: 1100px;
}

.client-detaildrawer .drawer {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.client-detaildrawer .drawer .close {
  top: 0;
  position: absolute;
  right: 0;
}

/* client-screen-3 */

.client-screen-3 .btn.btn-outline,
.client-screen-two .btn.btn-outline {
  border-color: #999;
  color: #000;
  font-size: 14px;
}

.client-screen-3 .btn.btn-outline:hover,
.client-screen-two .btn.btn-outline:hover {
  color: #fff;
  background-color: #8c1c4d;
  border-color: #8c1c4d;
}

.client-screen-3 #listview .table-responsive {
  border: 0;
  max-width: 100%;
  box-shadow: none;
  margin-top: -1px;
}

.client-screen-3 .list-view-content {
  border-top: 1px solid #eee;
}

.client-screen-3 #listview table .user-initial {
  height: 60px;
  width: 60px;
  line-height: 60px;
  margin-right: 55px;
}

.client-screen-3 #listview table .user {
  height: 60px;
  width: 60px;
  margin-right: 55px;
}

.client-screen-3 #listview .table tr td {
  padding: 10px 30px;
}

.client-screen-3 #listview .table thead th {
  padding: 20px 30px;
}

.client-screen-3 #listview .table thead tr th:first-child {
  padding-left: 145px;
}

/* client-screen-two */

.client-screen-two {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.client-screen-two > .row,
.client-screen-two .client-detaildrawer > .row {
  height: 100%;
}

.client-screen-two .client-detaildrawer {
  width: 100%;
  border-left: 1px solid #cacaca;
  height: 100%;
}

.client-screen-two .list-block .search {
  width: calc(100% - 235px);
}

.client-screen-two .list-block .btn-wrap {
  width: auto;
}

.client-screen-two .user-list {
  background-color: #f3f4f6;
  height: calc(100% - 54px);
}

.client-screen-two .user-list li a {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 15px;
}

.client-screen-two .user-list li a {
  text-decoration: none;
}

.client-screen-two .user-list li a .user-name {
  color: #374151;
  display: block;
  font-size: 14px;
  margin: 0 0 4px;
}

.client-screen-two .user-list li a .user-phone,
.client-screen-two .user-list li a .user-id {
  font-size: 12px;
  color: #6b7280;
  display: block;
  margin: 0 0 2px;
}

.client-screen-two .client-detaildrawer .content-wrp {
  height: auto !important;
}

.client-screen-two .drawer {
  width: 530px;
  width: 100%;
  position: absolute;
  z-index: 0;
}

.client-screen-two .client-detaildrawer .close-drawer {
  right: 0;
  top: 0;
}

.client-invoice-drawer .sale-comlete-data {
  width: 100%;
}

.client-drawer#addclient-drawer {
  width: 850px;
}

.client-drawer#addclient-drawer .insert-photo {
  width: 250px;
  height: 250px;
  background-color: #f4eeeb;
  border-radius: 100%;
  text-align: center;
}

.client-drawer#addclient-drawer .insert-photo input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 1;
}

.client-drawer#addclient-drawer .insert-photo .btn:hover {
  background-color: #828df8;
  color: #fff;
  border-color: #828df8;
}

.client-drawer#addclient-drawer .insert-photo img {
  max-width: 266px;
}

.client-drawer#addclient-drawer .drawer-footer:after {
  display: none;
}

.client-drawer#addclient-drawer .input-file span.remove-img {
  top: -3px;
  right: 0px;
}
.viewappoinment .address {
  font-size: 18px;
}

.viewappoinment .address b {
  font-weight: 600;
}

.viewappoinment .desc p {
  font-size: 16px;
}

/* signup page css  */

.signup-page {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.signup-page .signup-header {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  padding: 35px;
  z-index: 1;
}

.signup-page .signup-content {
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 100vh;
  position: relative;
  padding: 277px 0 100px;
}

.signup-page .signup-header-right .already-login {
  margin-bottom: 0;
}

.signup-page .signup-header-right .already-login,
.signup-page .signup-header-right .already-login a {
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
}

.signup-page .signup-header-right .already-login a {
  color: #8c1c4d;
  margin-left: 5px;
  text-decoration: none;
}

.signup-page .container {
  max-width: 1040px;
}

.signup-page h1 {
  font-weight: 600;
  font-size: 70px;
  color: #8c1c4d;
  font-family: 'Source Sans Pro', sans-serif;

}

.signup-page p {
  font-weight: 500;
  font-size: 14px !important;
  color: #3a3432;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 20px;
  text-align: justify;
}

.signup-page ul {
  list-style: none;
  padding: 0;
}

.signup-page ul li {
  font-weight: 600;
  font-size: 18px;
  color: #8c1c4d;
  background-image: url(../images/listing.png);
  background-repeat: no-repeat;
  padding-left: 40px;
  margin-bottom: 10px;
}

.signup-form {
  border-radius: 30px;
  background-color: #fff;
  padding: 35px;
}

.signup-form .form-title {
  color: #8c1c4d;
  font-weight: 800;
  text-align: center;
}

.signup-page .progress {
  height: 4px;
  background-color: transparent;
  position: relative;
  top: -69px;
}

.signup-form .form-control {
  border: 1px solid #fbd2c3;
  border-radius: 8px;
  padding: 12px 15px;
}

.signup-form label {
  font-size: 16px;
  font-weight: normal;
  font-family: "Source Sans Pro", sans-serif;
  color: #000;
}

.signup-form .btn {
  font-weight: 600;
  border-radius: 10px;
  background-color: #bb5876;
  border-color: #bb5876;
  font-size: 19px;
  color: #fff;
}

.signup-form .btn:hover {
  border-color: #8c1c4d;
  color: #fff;
  background-color: #8c1c4d;
}

.signup-form .checkbox label {
  font-size: 18px;
  font-weight: 500;
}

.signup-form .checkbox label a {
  color: #8c1c4d;
  text-decoration: underline;
  font-size: inherit;
}

.signup-form .btn.previous {
  background-color: #3a3432;
  border-color: #3a3432;
}

.signup-form .btn.previous:hover {
  background-color: #000;
  border-color: #000;
}

.signup-form .checkbox label:before {
  border-color: #fbd2c3;
}

.signup-form .checkbox input[type="checkbox"]:checked + label:before {
  border-color: #8c1c4d;
}

.signup-form .staff-box {
  text-align: center;
  position: relative;
  padding: 15px;
}

.staff-box input[type="radio"] {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 10px;
  cursor: pointer;
}

.staff-box input[type="radio"]:checked {
  border: 1px solid #8c1c4d;
}

.signup-form .staff-box label {
  font-size: 18px;
  color: #999999;
  font-weight: 800;
  margin: 10px 0 0;
  line-height: normal;
}

.signup-page .stepsbox {
  max-width: 635px;
}

.signup-form.opening-hours .btn {
  min-width: 175px;
}

.signup-form.opening-hours {
  padding: 30px 0;
}

.signup-form.opening-hours .form-body {
  border-top: 1px solid #eee;
  margin: 25px 0;
}

.signup-form.opening-hours .form-switch span {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  padding-left: 25px;
}

.signup-form.opening-hours .hours-box {
  border-bottom: 1px solid #eee;
  padding: 15px 40px;
}

.signup-form.opening-hours .form-control {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 8px 15px;
}
.signup-form.opening-hours .was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 1px solid #ef0000 !important;
  background-image: none;
}
.signup-form.opening-hours .form-check {
  margin: 0;
}

.signup-form .business-location {
  background-image: url(../images/form-location.png);
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 40px;
}

.signup-form .business-location.is-invalid {
  background-image: url(../images/form-location.png) !important;
  background-size: auto;
}

.signup-form.opening-hours .hrs-close {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
}

.signup-form.opening-hours .totext {
  color: #999;
  font-size: 16px;
}

.signup-page .steps {
  display: none;
}

.signup-page .steps.active {
  display: block;
}
.alltab-box {
  height: 100vh;
}
/*toast notification css*/
.toast-success-container {
  color: #fff !important;
  background: #8c1c4d !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

/* aacount setup page css  */

.ac-setup .acsetup-left {
  padding: 15px;
  padding: 18px 20px;
  width: 230px;
  height: 100%;
}

.ac-setup .acsetup-right {
  width: calc(100% - 230px);
  position: relative;
  height: 100%;
  background-color: #f9f6f4;
}

.ac-setup .acsetup-right .alltab-box {
  padding: 35px 40px;
}

.ac-setup .acsetup-left .nav-link {
  font-size: 16px;
  color: #333;
  padding: 9px 0;
  display: block;
  text-align: left;
  width: 100%;
}

.ac-setup .alltab-box .box-image-cover {
  min-height: 220px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
}

.ac-setup .alltab-box .box-image-cover h6 {
  color: #374151;
  font-weight: 600;
  margin: 0;
}

.ac-setup .acsetup-right .alltab-box .row {
  width: calc(100% + 30px);
  margin: 0 -15px;
}

.ac-setup .acsetup-right .alltab-box img{
  width: 70px;
}
.tabs-image img{
  max-width: 100%;
  object-fit: cover;
}
.ac-setup .acsetup-right .tab-content {
  position: relative;
  width: 100%;
  border-left: 1px solid #eee;
  height: 100%;
  background-color: #fff;
}

.ac-setup .acsetup-right .tab-content .tab-pane {
  padding: 35px 40px;
  height: 100%;
}

.ac-setup .acsetup-right .tab-content.open {
  display: block;
}

.ac-setup-page.page-content {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  min-height: 100vh;
}

.ac-setup {
  width: 100%;
  flex-wrap: wrap;
}

.ac-setup .acsetup-left .nav-link.active {
  background-color: transparent;
  color: #333;
  font-weight: 600;
}

.ac-setup #v-pills-businessdetails p {
  color: #999999;
  font-size: 16px;
}

.ac-setup .input-field:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding: 0 0 20px;
  margin: 0 0 20px;
  /* margin: 0 0 30px; */
}

.ac-setup .tab-content .input-file {
  border-radius: 5px;
  height: 200px;
  width: 200px;
}

.ac-setup #v-pills-businessdetails .btn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px;
  min-width: 115px;
  padding: 10px 15px;
}

.ac-setup .inti-box {
  border: 1px solid #d4d4d4;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  height: 100%;
  padding: 40px 30px 70px;
  position: relative;
}

.ac-setup #v-pills-Integrations {
  height: 100%;
  background-color: #f9f6f4;
}

.ac-setup .inti-box .img-wrp {
  min-height: 130px;
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ac-setup .inti-box .btn {
  min-width: 115px;
  border-radius: 2px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.ac-setup .inti-box p {
  font-size: 16px;
}

.newsale-probox .qty {
  width: auto;
}

.newsale-probox .qty input {
  width: 62px;
}

.newsale-probox .pro-img img {
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.newsale-probox .pro-content {
  width: calc(100% - 41px);
  padding-left: 10px;
}

.newsale-probox .product-box .close,
.newsale-probox .membership-box .close {
  opacity: 1;
  visibility: visible;
}

.payment-option .pay-voucher {
  background-color: #8f807d;
  border-color: #8f807d;
}

.payment-option .pay-voucher:hover {
  border-color: #8f807d;
  background-color: transparent;
  color: #8f807d;
}

#salevoucher-drawer .drawer-body .left-col {
  background-color: #f9f6f4;
}

#salecomplete-invoice-drawer .full-screen-drawer-footer .form-control {
  padding: 22px 15px;
}

#salecomplete-invoice-drawer .full-screen-drawer-footer .btn-dark {
  padding: 18px 15px;
  min-width: 130px;
  font-size: 20px;
}

.product-box .price-input input {
  width: 80px;
}

.payment-option .btn-lg {
  height: 100%;
}

.bootstrap-datetimepicker-widget table.table > :not(caption) > * > * {
  padding: 0px;
}

.white-space-pre-line {
  white-space: pre-line !important;
}

.addcancellationreason-drawer .drawer-body .btn {
  border-radius: 2px;
  font-size: 13px;
  padding: 10px 21px;
}

.addcancellationreason-drawer .close {
  background-color: #f4eeeb;
  border-color: #f4eeeb;
  color: #3a3432;
}

.addcancellationreason-drawer .close:hover {
  background-color: #8c1c4d;
  border-color: #8c1c4d;
  color: #fff;
}

.addcancellationreason-drawer .drawer-body {
  max-width: 450px;
  margin: 0 auto;
}

.addcancellationreason-drawer label {
  color: #3a3432;
  font-weight: 500;
}

/* 29-04-2022 account tabs start */

.ac-setup .acsetup-right .tab-content .tab-pane#v-pills-ClosedDates,
.ac-setup .acsetup-right .tab-content .tab-pane#v-pills-ConsultationForms {
  padding: 0;
  background-color: #f9f6f4;
}

.ac-setup .acsetup-right #v-pills-ClosedDates .left-content,
.ac-setup .acsetup-right #v-pills-ConsultationForms .left-content {
  padding: 35px 40px;
}

.ac-setup .acsetup-right #v-pills-ClosedDates .right-content,
.ac-setup .acsetup-right #v-pills-ConsultationForms .right-content {
  padding: 40px;
}

.acsetup-right #v-pills-ClosedDates .btn,
.addclosedate-drawer .drawer-body .btn,
.acsetup-right #v-pills-ConsultationForms .btn {
  border-radius: 2px;
  font-size: 13px;
  padding: 10px 21px;
}

.acsetup-right #v-pills-ClosedDates > .row,
.acsetup-right .tab-content .tab-pane#v-pills-ConsultationForms > .row {
  height: 100%;
}

.addclosedate-drawer .close {
  background-color: #f4eeeb;
  border-color: #f4eeeb;
  color: #3a3432;
}

.addclosedate-drawer .close:hover {
  background-color: #8c1c4d;
  border-color: #8c1c4d;
  color: #fff;
}

.addclosedate-drawer .drawer-body {
  max-width: 450px;
  margin: 0 auto;
}

.addclosedate-drawer label {
  color: #3a3432;
  font-weight: 500;
}

.addclosedate-drawer input.date {
  background-position: 8px center !important;
  padding-right: 10px !important;
  padding-left: 35px !important;
}

.acsetup-right #v-pills-ClosedDates .box-image-cover:last-child {
  margin-bottom: 0;
}

#v-pills-ClientNotifications .box-image-cover .active {
  background-color: #04d37c;
}

#v-pills-ClientNotifications .box-image-cover .active:hover {
  background-color: #828df8;
}

#v-pills-ClientNotifications .box-image-cover span {
  color: #8f807d;
}

/* 29-04-2022 account tabs end */

.drawer-form-nofound {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  text-align: center;
  padding: 50px;
  margin-bottom: 20px;
}

/* 04/04/2022 New Sale page vouchers box start*/

.voucher-center-box {
  background-color: #f4eeeb;
  max-width: 118px;
  height: 118px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.voucher-center-box img {
  max-width: 51px;
}

.voucher-center-box span {
  font-size: 28px;
  line-height: 31px;
  font-weight: 600;
  color: #8c1c4d;
}

/* one-off Voucher page start  */

.one-off-voucher {
  border-bottom: 1px solid #eee;
}

.one-off-voucher .cancel_btn {
  text-decoration: none;
  text-transform: capitalize;
  background-color: #f4eeeb;
  padding: 12px 36px;
  font-size: 18px;
  line-height: 25px;
  color: #333;
  font-weight: 600;
}

/* from start  */

.gift-vocher-page-form {
  padding-top: 80px;
}

.gift_form_cover {
  max-width: 500px;
  margin: 0 auto;
}

.gift_form_cover h3 {
  text-transform: capitalize;
  font-weight: 700;
}

.gift_form_cover form input[type="submit"] {
  padding: 16px 0;
}

.gift_form_cover form label {
  color: #333;
}

.gift_form_cover form input[type="submit"]:hover {
  color: #000 !important;
}

.form-control:focus,
.dropdown-toggle:focus {
  border-color: unset;
}

.birthday_vocher {
  position: relative;
}

.birdthday_addtop {
  background-color: #8c1c4d;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  position: absolute;
  color: #fff;
  padding-top: 5px;
  top: -14px;
  right: -12px;
}

.birdthday_addtop p {
  color: #fff;
}

/*11-04-2022 membership active  */

.event-box-pink {
  color: #374151;
  background-color: #f9f6f4;
  display: block;
  padding: 15px 24px;
  border-radius: 6px;
}

.event-box-pink span.active {
  background-color: #8c1c4d;
  color: #fff;
  font-size: 12px;
}

.event-box-pink .cancel {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  background-color: #3a3432;
  padding: 2px 6px;
  display: inline-block;
  vertical-align: middle;
  line-height: 16px;
}

/* subscription */

.page-content.subscription {
  background-color: #f9f6f4;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.subscription .common-tab .nav-tabs li a.active {
  color: #8c1c4d;
  border-color: #8c1c4d;
}

.subscription .complete-box a {
  color: #8c1c4d;
  font-size: inherit;
  cursor: pointer;
  border-bottom: 2px solid #991d53;
}

.subscription .complete-box a:hover {
  color: #8c1c4d;
  font-size: inherit;
  cursor: pointer;
  border-bottom: 1px solid #8c1c4d;
}

.subscription-service {
  background-color: #f9f6f4;
}

.subscription-service .accordion-button {
  padding: 20px 25px;
}

.subscription-drawer .add-form,
.subscription-drawer form,
.subscription-drawer .row {
  /* min-height: 100vh; */
}

.subscription-drawer .qty {
  width: 37px;
}

.subscription-drawer .card {
  padding: 20px;
}

.subscription-drawer .card .close {
  color: #ef0000;
}

.subscription .box-image-cover .user-initial {
  font-size: 20px;
}

.box-image-cover .edit,
.box-image-cover .sell,
.box-image-cover .delete,
.box-image-cover .active {
  background-color: #8f807d;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 10px;
  text-decoration: none;
  color: #fff;
  min-height: 29px;
  min-width: 36px;
  text-align: center;
  cursor: pointer;
}

.box-image-cover .delete img {
  max-width: 12px;
  display: block;
  filter: brightness(0) invert(1);
  height: 12px;
  margin: 1px auto;
}

.box-image-cover .edit:hover,
.box-image-cover .sell:hover,
.box-image-cover .delete:hover,
.box-image-cover .active:hover {
  background-color: #8c1c4d;
}

.substripe-complete {
  background: #fffbd7;
  border: 5px solid #ffffff;
}

.substripe-content {
  max-width: 450px;
  margin: 0 auto;
}

.substripe-content h6 {
  line-height: 1.5;
}

.integration-stripe,
.integration-stripe > .row {
  min-height: 100vh;
}

.integration-stripe .content-block {
  background-color: #8c1c4d;
  padding: 170px 100px 40px 40px;
}

.integration-stripe .content-block h2,
.integration-stripe .form-block h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
}

.integration-stripe .content-block a {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}

.integration-stripe .form-block {
  padding: 170px 40px 40px 120px;
  max-width: 720px;
}

.integration-stripe .form-block h2 {
  color: #3a3432;
}

.integration-stripe .form-block p {
  font-weight: 400;
  font-size: 19px;
  color: #787878;
}

.integration-stripe .form-block label {
  color: #3a3432;
  font-size: 19px;
  font-weight: bold;
}

.integration-stripe .form-control {
  border-radius: 10px;
  border: 1px solid #3a3432;
  font-size: 19px;
  padding: 14px 25px;
}

.integration-stripe .form-control::-webkit-input-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.integration-stripe .form-control::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.integration-stripe .form-control:-ms-input-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.integration-stripe .form-control::-ms-input-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.integration-stripe .form-control::placeholder {
  color: #bfbfbf;
  opacity: 1;
}

.integration-stripe .btn {
  font-size: 19px;
  font-weight: bold;
  border-radius: 10px;
  padding: 17px 10px;
}

#subscriptions .alert svg {
  min-width: 30px;
}

body .border-block {
  border-color: #333333;
}

.voucher-box {
  background: #f9f6f4;
  border-radius: 6px;
  padding: 20px;
}

.btn-voucher {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  background-color: #8f807d;
  text-decoration: none;
  padding: 6px 14px;
}

.btn-voucher:hover {
  background-color: #828df8;
}

.voucher-box .img-wrp img {
  min-width: 30px;
}

/* New Sale - Pay with Stripe (Credit Card) */

.newsalecreditpay label {
  color: #374151;
  font-family: "Source Sans Pro", sans-serif;
}

.newsalecreditpay .form-control {
  font-size: 22px;
  font-family: "Source Sans Pro", sans-serif;
}

.form-control.cvc {
  background-image: url(../images/cvc.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
}

.form-control.creditcard {
  background-image: url(../images/visa.svg), url(../images/mastercard.svg);
  background-repeat: no-repeat;
  background-position: 90.2% center, 97.6% center;
  margin-bottom: -1px;
}

.newsalecreditpay .form-control.lg {
  padding: 24px 17px;
}

.newsalecreditpay .btn {
  font-size: 26px;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1;
  padding: 20px 15px;
}

.newsale-payment-btn .btn {
  font-size: 20px;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1;
  padding: 23px 15px;
}

.newsalecreditpay .month {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.salecheckout-drawer hr {
  background-color: #eee;
  opacity: 1;
  width: calc(100% + 60px);
  margin-left: -30px;
}

/* newsale-payment-btn */

/* dashboard page css start */

.dashboard {
  background-color: #f9f6f4;
  padding: 45px 35px;
  min-height: 100%;
}

.box-common {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.dashboard .left-col .user-box .user-initial {
  font-size: 18px;
  overflow: hidden;
}

.dashboard .user-box .user-initial img {
  display: block;
}

.dashboard .user-box:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.dashboard .box-col {
  width: 12.5%;
}

.dashboard .box-col .box-common {
  height: 100%;
}

.dashboard .right-col .box-image-cover .user-initial {
  height: 124px;
  width: 124px;
}

.dashboard .view-report {
  border-radius: 8px;
}

.chat-avg sup {
  font-size: 16px;
  font-weight: 500;
  color: #969696;
  vertical-align: top;
  top: 0;
  line-height: 1;
}

.chat-avg .upvalue {
  color: #00ba34;
  font-size: 13px;
  background-color: #e5f8eb;
  display: inline-block;
  border-radius: 6px;
  vertical-align: top;
  padding: 5px 8px;
}
.chat-avg .downvalue {
  color: #f43f5e;
  font-size: 13px;
  background-color: #fae6e9;
  display: inline-block;
  border-radius: 6px;
  vertical-align: top;
  padding: 5px 8px;
}

.chat-avg .upvalue i,
.chat-avg .downvalue i {
  font-size: 11px;
}

.dashboard .left-col ul {
  height: 833px; 
  overflow-y: auto; 
}

.dashboard .left-col ul::-webkit-scrollbar {
  width: 14px;
}

.dashboard .left-col ul::-webkit-scrollbar-track {
  background: linear-gradient(0deg, #f9f6f4, #f9f6f4), #f9f6f4;
  border-radius: 12px;
}

.dashboard .left-col ul::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #f0e5e0, #f0e5e0), #f9f6f4;
  border-radius: 12px;
}

/* Consultation Form Start By Zankhana */

.Consultation-Preview-Modal,
.black-backdrop {
  background-color: rgb(0 0 0 / 60%);
}

.editconsulationform-drawer .drawer-body {
  height: calc(100vh - 74px);
  overflow-y: auto;
  background-color: #f9f6f4;
}

.editconsulationform-drawer .box-image-cover {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  padding: 35px 10px;
  height: 126px;
}

.editconsulationform-drawer .left-content {
  padding: 28px 32px;
}

.editconsulationform-drawer .right-content {
  padding: 47px;
}

.editconsulationform-drawer .box-image-cover .image-content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3a3432;
}

.editconsulationform-drawer .box-image-cover .image-content {
  padding-top: 15px;
}

.editconsulationform-drawer .right-content .drawer-form-box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 12px 15px;
}

.editconsulationform-drawer .right-content .form-header h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #8f807d;
}

.editconsulationform-drawer .right-content .form-body .drawer-form-box {
  padding: 20px 28px;
}

.editconsulationform-drawer .right-content .form-body .drawer-form-box label.form-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a3432;
}

.editconsulationform-drawer .right-content .form-body .drawer-form-box h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #333333;
}

.drawer-form-box .edit,
.drawer-form-box .delete {
  background-color: #8f807d;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 10px;
  text-decoration: none;
  color: #fff;
  min-height: 27px;
  min-width: 36px;
  text-align: center;
  cursor: pointer;
}

.drawer-form-box .edit:hover,
.drawer-form-box .delete:hover {
  background-color: #828df8;
}

/* Preview Modal Css Start */

.edit-paradetails-modal .modal-dialog {
  max-width: 600px;
}

.Edit-modal .modal-header h4 {
  font-size: 15px;
  line-height: 19px;
  color: #8f807d;
  font-weight: 400;
}

.Edit-modal .modal-content {
  padding: 25px 35px;
}

.Edit-modal .consent-check-control .form-check {
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
}

.Edit-modal .modal-body label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a3432;
}

.Edit-modal .modal-body p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
}

.Edit-modal .consent-check-control .form-check label {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #333333;
  margin-left: 15px;
}

.Edit-modal .btn-close {
  padding: 0;
  opacity: 1;
  width: 25px;
  height: 25px;
  font-size: 25px;
}

.Edit-modal .btn-close:hover {
  opacity: 0.5;
}

.Edit-modal .btn-edit-modal {
  min-width: 100px;
}

.consent-check-control .form-check .form-check-input {
  width: 28px;
  height: 26px;
  margin-left: -20px;
  border-color: #e5e7eb;
}

.Edit-modal-preview .modal-header h4 {
  font-size: 15px;
  line-height: 19px;
  color: #8f807d;
  font-weight: 400;
}

.Edit-modal-preview .modal-content {
  padding: 25px 35px;
}

.Edit-modal-preview .consent-check-control .form-check {
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
}

.Edit-modal-preview .modal-body label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a3432;
}

.Edit-modal-preview .modal-body p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
}

.Edit-modal-preview .consent-check-control .form-check .form-check-label {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #333333;
  margin-left: 15px;
}

.Edit-modal-preview .btn-close {
  padding: 0;
  opacity: 1;
  width: 25px;
  height: 25px;
  font-size: 25px;
}

.Edit-modal-preview .btn-close:hover {
  opacity: 0.5;
}

.Edit-modal-preview .btn-edit-modal {
  min-width: 100px;
}

/* Preview Modal Css End */

/* Consultation Form End */

/* Reports Page Start */

.reports-content h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #333333;
}

.reports-content h4 {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: #8c1c4d;
  margin-bottom: 5px;
}

.reports-content p {
  color: #8f807d;
  font-size: 16px;
  font-weight: 400;
}

.reports-content .report-box {
  padding: 40px 40px;
}

.reports-content .report-box a {
  display: block;
}

.report-box.border-bottom {
  border-bottom: 0.75px solid #eeeeee !important;
}

.border-left-right {
  position: relative;
}

.border-left-right::before,
.border-left-right::after {
  content: "";
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  border-left: 0.75px solid #eeeeee;
  width: 1px;
  height: 100vh;
}

.border-left-right::after {
  border-left: 0.75px solid #eeeeee;
  left: auto;
  right: 0;
}

.edit-paradetails-modal .para-box {
  padding: 15px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
}

/* Performance Summary Modal Css Start */

.Reports-drawer .drawer-body {
  padding: 28px 36px;
}

.drawer-panel-header {
  background-color: #f9f6f4;
  border-radius: 13px;
  height: 73px;
  padding: 18px 22px;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown.staff-dropdown .dropdown-toggle {
  font-size: 14px;
  padding: 6px 25px 6px 8px;
  font-family: "Source Sans Pro", sans-serif;
}

.download-table-xls-button {
  padding: 8px 15px;
  min-width: 115px;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #3a3432;
  border: none;
  border-radius: 2px;
  box-shadow: none;
  background: #fff;
}

.export-dropdown .dropdown-box.show {
  right: 0 !important;
  left: auto !important;
}

.drawer-panel-body table > thead > tr > th {
  color: #3a3432;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 13px;
}

.table.border-less th,
.table.border-less td {
  border: none;
}

.drawer-panel-body table {
  width: 100%;
}

.drawer-panel-body table tbody td {
  text-align: inherit;
  vertical-align: top;
  border-collapse: collapse;
  padding: 15px 15px;
}

.drawer-panel-body table tfoot td {
  text-align: inherit;
  vertical-align: top;
  border-collapse: collapse;
  padding: 15px 15px;
  font-weight: bold;
}

.drawer-panel-body .table.nested-table th {
  padding: 15px 0 15px 15px;
}

.drawer-panel-body table tbody td.p-0 {
  border-bottom: none;
}

.drawer-panel-body .table > :not(:last-child) > :last-child > * {
  border-color: inherit;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #8c1c4d !important;
}

.staff-dropdown .user-img img {
  max-width: 100%;
  object-fit: cover;
}
/* Performance Summary Modal Css Start */
/* Reports Page End */

/* 30-06-2022- Marketing Page By Zankhana Start*/
.theme-btn {
  min-width: 114px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
}
.page-marketing p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #374151;
}
.page-marketing .box-image-cover.box-marketing {
  margin: 0 0 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
}
/* Birthday Offer Modal Start */

.birthdayoffer-drawer .right-content {
  border-left: 1px solid #eee;
}
.birthdayoffer-drawer .left-content .input-field label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3a3432;
}
.birthdayoffer-drawer .left-content .input-field {
  border-bottom: 0.75px solid #eeeeee;
  position: relative;
  padding: 30px 50px;
}
.birthdayoffer-drawer .left-content .input-field input.form-control::placeholder {
  color: #374151;
}
.birthdayoffer-drawer .left-content .input-field p {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
}
.birthdayoffer-drawer .left-content .input-field .subtitle-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
}
.birthdayoffer-drawer .left-content .input-field select.form-control {
  color: #6b7280;
}
.bordered-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  border: 1px solid #e5e7eb;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  width: 180px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.bordered-btn:hover {
  background-color: #f4eeeb;
  color: #374151;
}
/* Birthday Offer Modal End */
/* 30-06-2022- Marketing Page By Zankhana End */

/* Choose an image Modal 01-07-2022-By Zankhana Start */
.chooseimg-section .tab-content {
  overflow-y: auto;
  max-height: calc(100vh - 435px);
}
.change-headerimg-modal .modal-lg {
  max-width: 872px;
  max-height: 779px;
}
.change-headerimg-modal .modal-content {
  padding: 0;
}
.change-headerimg-modal .btn-close {
  padding: 0;
  opacity: 1;
  width: 25px;
  height: 25px;
  font-size: 0;
  opacity: 0.5;
}
.change-headerimg-modal .btn-close:hover {
  opacity: 1;
}
.change-headerimg-modal .modal-content .modal-header {
  padding: 25px 35px 0;
}
.change-headerimg-modal .common-tab .nav {
  padding: 0 37px;
  border-bottom: 3px solid #f4eeeb;
}
.change-headerimg-modal .common-tab .nav-tabs li a {
  margin-bottom: -3px;
  font-size: 16px;
  font-weight: 400;
}
.chooseimg-section .tab-content {
  overflow-y: auto;
}
.chooseimg-section .tab-content::-webkit-scrollbar {
  width: 10px;
}

.chooseimg-section .tab-content::-webkit-scrollbar-track {
  background: #c4c4c4;
  border-radius: 20px;
}

.chooseimg-section .tab-content::-webkit-scrollbar-thumb {
  background-color: #bb5876;
  outline: none;
  border-radius: 20px;
}
.preview-offer h1 {
  font-size: 50px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 15px;
}
.preview-offer p.offer-subtitle-txt {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #374151;
  margin: 0 auto 30px;
}
.preview-offer .offer-box {
  padding: 48px 45px;
  max-width: 408px;
  background: #fdc668;
  border-radius: 20px;
  margin: 0 auto 30px;
}
.preview-offer .offer-box h1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 20px;
}
.preview-offer .offer-box p {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
}
.preview-offer .offer-address p,
.preview-offer .offer-address h6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #374151;
  margin-bottom: 30px;
}
.preview-offer .offer-address h6 {
  font-weight: 800;
  line-height: 18px;
  margin-bottom: 15px;
}
.preview-offer .offer-details {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  width: 65%;
  margin: 0 auto;
  text-align: justify;
}
/* Choose an image Modal 01-07-2022-By Zankhana Start End */
.oneoff-tab .box-image-cover .tabs-image {
  width: 107px;
  height: 107px;
  margin: 0 auto 0;
  border-radius: 50%;
  background: #f9f6f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-campaigns-drawer .box-image-cover {
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  margin: 0 20px;
}
.mobile-data {
  max-width: 295px;
  margin: 0 auto;
  padding-top: 520px;
}
.mobile-data .mobile-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.mobile-data .mobile-txt {
  top: 80px;
  background-color: #8c1c4d;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 11px;
  position: absolute;
  margin: 0 26px;
}
.mobile-data .mobile-txt a {
  color: #fff;
}
.pest-link {
  background-color: #f9f6f4;
  border-radius: 5px;
  padding: 8px 14px;
  word-break: break-all;
}
/* Media query */
@media only screen and (min-width: 1360px) {
  .row.gutter-40 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .row.gutter-40 > div[class^="col"] {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) {
  .addphoto-drawer,
  .client-invoice-drawer,
  .adddoc-drawer,
  .addnote-drawer,
  .adddoc-drawer {
    box-shadow: none;
  }
  .search {
    position: relative;
  }
  .calendar-header .staff-dropdown .dropdown-toggle {
    line-height: normal;
  }
  .drawer.busytime-drawer {
    width: 550px;
  }
  .box-image-cover {
    padding: 25px 15px;
  }
}

@media only screen and (max-width: 1599px) {
  .voucher-avilable .complete-box {
    height: 370px;
    width: 370px;
  }
}

@media only screen and (max-width: 1399px) {
  .voucher-avilable .complete-box {
    height: 300px;
    width: 300px;
  }
  .voucher-avilable .complete-box img {
    max-width: 110px;
  }
  .voucher-form,
  .voucher-preview,
  .voucher-preview-inner,
  .voucher-avilable {
    padding: 30px;
  }
  .voucher-preview-inner h1 {
    font-size: 45px;
  }
  .voucher-preview-inner h2 {
    font-size: 30px;
  }

  .box-image-cover {
    width: calc(25% - 30px);
  }
  .signup-page .signup-content {
    padding: 200px 0 100px;
  }
  .signup-page h1 {
    font-size: 60px;
  }
  .dashboard {
    padding: 45px 25px;
  }
  .dashboard .left-col ul::-webkit-scrollbar {
    width: 8px;
  }
  .dashboard .left-col .user-box .user-initial {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  .dashboard .box-col {
    width: 25%;
  }
  .dashboard .right-col .box-image-cover .user-initial {
    height: 114px;
    width: 114px;
  }
  .dashboard .time {
    font-size: 13px;
  }
  .dashboard .right-col .box-image-cover h5 {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1280px) {
  .row.gutter-40 > div[class^="col"] {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1199px) {
  h1,
  .h1 {
    font-size: 36px;
  }
  h2,
  .h2 {
    font-size: 28px;
  }
  #preloader img {
    max-width: 240px;
  }
  .sales-list .sales-list-header input.date {
    min-width: auto;
  }
  .sales-list-header .search {
    width: auto;
  }
  .complete-box img {
    max-width: 150px;
  }
  .complete-box {
    height: 350px;
    width: 350px;
  }
  #salecomplete-drawer .btn-dark {
    min-width: 120px;
  }
  .product-box form .discount {
    width: 100%;
    margin-top: 5px;
  }
  .full-screen-drawer .right-col .btn-lg {
    padding: 15px;
    font-size: 16px;
  }
  .search.large-input .input-group .search-input {
    padding: 10px 30px 10px 40px;
    font-size: 18px;
  }
  .search.large-input .input-group .input-group-text {
    left: 15px;
  }
  .right-col .search-result li a {
    padding: 12px 15px;
  }
  .voucher-avilable .complete-box {
    height: 250px;
    width: 250px;
  }
  .voucher-avilable .complete-box h4 {
    font-size: 18px;
  }
  .voucher-avilable .complete-box img {
    max-width: 90px;
  }
  .voucher-form,
  .voucher-preview,
  .voucher-preview-inner,
  .voucher-avilable {
    padding: 20px;
  }
  .voucher-preview-inner h1 {
    font-size: 40px;
  }
  .voucher-preview-inner h2 {
    font-size: 30px;
  }
  .voucher-preview-inner p {
    font-size: 18px;
  }
  .voucher-form p {
    padding: 15px;
  }
  .alert {
    padding: 15px;
    font-size: 14px;
  }
  .salevoucher-tab .nav-tabs li a {
    font-size: 16px;
    padding: 10px 13px;
  }
  .salevoucher-tab .voucher-box,
  .salevoucher-tab .membership-box {
    padding: 25px 15px;
  }
  .addstaff-member .detail,
  .addstaff-member .working-hrs,
  .addstaff-member .service {
    padding: 25px;
  }
  .input-file {
    height: 130px;
    width: 130px;
  }
  /* .input-file img {
    max-width: 50px;
  } */
  .addstaff-member .working-hrs span {
    font-size: 14px;
  }
  .checkbox label {
    font-size: 16px;
  }
  .addstaff-member .service li {
    padding: 22px 0 21px;
  }
  .addstaff-member .service ul {
    margin-top: 21px;
  }
  .add-form form > .row {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .common-tab .nav-tabs li a {
    padding: 15px 30px;
  }
  .accordion-button,
  .accordion-body ul li {
    font-size: 16px;
    padding: 15px;
  }
  .accordion-button::after {
    background-size: 15px;
  }
  .box-image-cover {
    width: calc(33.33% - 30px);
  }
  .list-view-content {
    padding: 25px;
  }
  .client-screen-3 #listview .table thead tr th:first-child {
    padding-left: 120px;
  }
  .client-screen-3 #listview table .user-initial,
  .client-screen-3 #listview table .user {
    margin-right: 30px;
  }
  .client-screen-two .client-detaildrawer {
    border-left: 0;
  }
  .client-screen-two .drawer {
    width: 490px;
    position: fixed;
    z-index: 9;
    top: 0;
  }
  .drawer {
    padding: 30px;
  }
  .calendar-header .staff-dropdown {
    width: 170px;
  }
  .calendar-header .custom-tab .list-group-item {
    min-width: 60px;
  }
  .signup-page .signup-header {
    padding: 20px;
  }
  .ac-setup .acsetup-right .alltab-box,
  .ac-setup .acsetup-right .tab-content .tab-pane {
    padding: 30px;
  }
  .ac-setup #v-pills-businessdetails p {
    font-size: 14px;
    line-height: normal;
  }
  .ac-setup .acsetup-left {
    width: 200px;
  }
  .ac-setup .acsetup-right {
    width: calc(100% - 200px);
  }
  .ac-setup #v-pills-businessdetails .btn {
    min-width: 100px;
  }
  .ac-setup .inti-box p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .ac-setup .inti-box {
    padding: 25px 15px 65px;
  }
  .ac-setup .inti-box .btn {
    bottom: 25px;
  }
  .ac-setup .inti-box .img-wrp img {
    max-width: 110px;
  }
  .ac-setup .inti-box .img-wrp {
    min-height: 110px;
  }
  .newsalecreditpay .form-control.lg {
    padding: 17px 17px;
  }
  .salecheckout-drawer hr {
    width: 100%;
    margin-left: 0;
  }
  .newsale-payment-btn .btn {
    font-size: 18px;
    padding: 20px 15px;
  }
  .ac-setup .acsetup-right #v-pills-ClosedDates .left-content,
  .ac-setup .acsetup-right #v-pills-ClosedDates .right-content {
    padding: 30px;
  }
  .acsetup-right #v-pills-ClosedDates > .row {
    height: auto;
  }
  .dashboard .left-col ul {
    height: 525px;
  }
}

@media only screen and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 34px;
  }
  h2,
  .h2 {
    font-size: 26px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  header {
    width: 100%;
    left: 0;
    padding: 0px;
  }
  .custom-header-padding-with-ad{
    padding: 15px;
  }
  /* .sidenav-bar {
    width: 280px;
    left: -280px;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  .sidenav-bar.open {
    left: 0;
  } */
  #preloader img {
    max-width: 200px;
  }
  .page-content {
    width: 100%;
    margin-left: 0;
  }
  .table > :not(caption) > * > * {
    padding: 10px;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr,
  .badge,
  .custom-tab .list-group-item {
    font-size: 14px;
  }
  .user-box .user-initial {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 28px;
  }
  .complete-box img {
    max-width: 100px;
  }
  .complete-box {
    height: 300px;
    width: 300px;
  }
  .full-screen-drawer .drawer-body {
    height: calc(100vh - 68px);
  }
  .Reports-drawer .drawer-body {
    padding: 15px;
  }
  .product-box .product-header p,
  .newsale-probox .membership-box .product-header p {
    font-size: 16px;
  }
  .mega-menu ul li a,
  .mega-menu .title {
    padding: 12px 24px;
  }
  .voucher-avilable {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #eee;
  }
  .voucher-form,
  .voucher-preview,
  .voucher-preview-inner,
  .voucher-avilable {
    padding: 20px 15px;
  }
  .voucher-avilable .complete-box h4 {
    font-size: 16px;
  }
  .voucher-avilable .complete-box img {
    max-width: 80px;
  }
  .voucher-avilable .complete-box {
    height: 220px;
    width: 220px;
  }
  /* .voucher-form,
  .voucher-preview {
    width: 50%;
  } */
  .voucher-preview-inner h1 {
    font-size: 36px;
  }
  .voucher-preview-inner h2 {
    font-size: 28px;
  }
  .box-image-cover {
    padding: 20px;
  }
  .tabs-image img {
    max-width: "100%";
  }
  .box-image-cover .user-initial {
    font-size: 26px;
    height: 90px;
    width: 90px;
    line-height: 90px;
  }
  .box-image-cover {
    padding: 25px;
  }
  #roster .add-time img {
    max-width: 12px;
  }
  #roster .updatetime-popup .popup-footer button,
  #roster .addtime-popup .popup-footer button {
    padding: 10px;
  }
  #roster table {
    table-layout: auto;
  }
  .addstaff-member .working-hrs {
    border-right: 0;
  }
  .addstaff-member .service {
    border-top: 1px solid #eee;
  }
  #addbreak-link {
    font-size: 14px;
  }
  .close-breaktime img {
    max-width: 12px;
  }
  .addstaff-member .drawer-footer .btn {
    min-width: auto;
  }
  .table-shadow {
    margin: 20px 0;
  }
  .common-tab .nav-tabs li a {
    padding: 15px 25px;
  }
  .services-table th:first-child {
    padding-left: 15px;
  }
  .services-table td:first-child {
    padding-left: 45px;
  }
  .services-table td:first-child:before {
    left: 15px;
  }
  .down-up-arrow i {
    font-size: 13px;
  }
  .user-upload-img img {
    max-width: 150px;
  }
  #addproduct-drawer .input-file,
  #addsuppliers-drawer .input-file,
  #editproduct-drawer .input-file,
  #editsuppliers-drawer .input-file {
    width: 170px;
    height: 180px;
  }
  .product-img img {
    max-width: 200px;
  }
  .service.list-page table tr td {
    font-size: 16px;
    padding: 15px 15px 15px 15px;
  }
  .service.list-page table tr td:first-child {
    padding-left: 50px;
  }
  .list-view-lable {
    display: none;
  }
  .list-view-content {
    padding: 20px;
  }
  .box-image-cover .image-content h5 {
    line-height: 1;
  }
  .client-detaildrawer {
    width: 90%;
  }
  .client-editappoinment#addappoinment-drawer .drawer-body {
    height: calc(100vh - 280px);
  }
  #listview table .user {
    height: 32px;
    width: 32px;
  }
  #listview table .user-initial {
    height: 32px;
    width: 32px;
    font-size: 14px;
    line-height: 32px;
  }
  .client-screen-3 #listview .table thead th,
  .client-screen-3 #listview .table tr td {
    padding: 10px 20px;
  }
  .client-screen-3 #listview table .user-initial,
  .client-screen-3 #listview table .user {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
  .client-drawer#addclient-drawer {
    width: 100%;
  }
  .signup-page .signup-content {
    padding: 150px 0 70px;
  }
  .signup-form {
    border-radius: 25px;
    padding: 25px;
  }
  .signup-page h1 {
    font-size: 50px;
  }
  .signup-form .checkbox label {
    font-size: 14px;
  }
  .signup-form .btn,
  .signup-page p {
    font-size: 16px;
  }
  .signup-page ul li {
    background-size: 20px;
    padding-left: 35px;
    font-size: 16px;
  }
  .ac-setup .alltab-box .box-image-cover {
    padding: 15px;
    min-height: 160px;
  }
  .ac-setup #v-pills-businessdetails .btn {
    position: static;
    margin-top: 15px;
  }
  .full-screen-drawer .drawer-panel-header {
    height: initial;
  }
}

@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 30px;
  }
  h2,
  .h2 {
    font-size: 24px;
  }
  h3,
  .h3 {
    font-size: 22px;
  }
  h4,
  .h4 {
    font-size: 20px;
  }
  h5,
  .h5 {
    font-size: 18px;
  }
  h6,
  .h6 {
    font-size: 16px;
  }
  .sale-comlete-data li span,
  .sale-comlete-data li p,
  .box-image-cover .image-content h5,
  #roster .updatetime-popup .popup-footer button,
  #roster .addtime-popup .popup-footer button .search-radius.search .input-group .search-input {
    font-size: 14px;
  }
  .page-content {
    padding-top: 63px;
  }
  .page-content.custom-pt-top-content {
    padding-top: 63px;
  }
  .user-profile-icon img {
    height: 32px;
    width: 32px;
  }
  .search .search-wrapper {
    top: 100%;
    width: calc(100% - 30px);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  header .search .search-wrapper form {
    width: 100%;
  }
  #preloader img {
    max-width: 150px;
  }
  .drawer {
    padding: 20px 25px;
    width: 320px;
  }
  .close-drawer img,
  .client-detaildrawer .drawer .close {
    max-width: 18px;
  }
  .drawer .drawer-body {
    height: calc(100vh - 95px);
  }
  .drawer .include-footer .drawer-body {
    height: calc(100vh - 155px);
  }
  #addappoinment-drawer .drawer-body {
    height: calc(100vh - 270px);
  }
  body .btn-lg {
    font-size: 17px;
    padding: 10px 15px;
  }
  .drawer-footer {
    padding-top: 20px;
  }
  .complete-box {
    height: 250px;
    width: 250px;
  }
  .full-screen-drawer .right-col {
    border-left: 0;
    border-top: 1px solid #eee;
  }
  .sale-comlete-data li label,
  .mega-menu .title,
  .mega-menu ul li a {
    font-size: 16px;
  }
  .full-screen-drawer .drawer-body {
    height: calc(100vh - 66px);
  }
  #salecomplete-drawer .btn-dark {
    min-width: auto;
  }
  .full-screen-drawer .right-col .btn-lg {
    padding: 12px 15px;
    font-size: 18px;
  }
  .product-box,
  .newsale-probox .membership-box {
    padding: 15px 40px 15px 15px;
  }
  .product-box form .price {
    width: 60%;
  }
  .product-box form .qty {
    width: 40%;
  }
  .form-control.lg {
    padding: 12px;
  }
  .complete-box img {
    max-width: 80px;
  }
  .voucher-avilable .complete-box h4 {
    font-size: 11px;
  }
  .voucher-avilable .complete-box img {
    max-width: 60px;
  }
  .voucher-avilable .complete-box {
    height: 150px;
    width: 150px;
    padding: 20px;
  }
  .voucher-form,
  .voucher-preview {
    width: 100%;
  }
  .voucher-preview-inner h1 {
    font-size: 32px;
  }
  .voucher-preview-inner h2 {
    font-size: 24px;
  }
  .common-tab .nav-tabs li a {
    padding: 10px 15px;
    font-size: 14px;
  }
  .box-image-cover {
    width: calc(50% - 20px);
    max-width: 100%;
    margin: 0 10px 20px;
    padding: 30px 10px 30px;
  }
  .box-image-cover .setting-dropdown {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .editconsulationform-drawer .box-image-cover {
    padding: 30px 10px 30px;
  }
  .tabs-image img {
    max-width: "100%";
  }
  .box-image-cover .user-initial {
    font-size: 20px;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  .membership-grid.box-image-cover {
    width: 100%;
    max-width: 100%;
    margin: 0 0 15px 0;
    padding: 30px 10px 10px;
  }
  .membership-grid.box-image-cover .user-initial {
    font-size: 20px;
    height: 124px;
    width: 124px;
    padding: 20px;
  }
  .box-image-cover .image-content {
    padding-top: 10px;
  }
  .box-image-cover.add-main-category {
    width: calc(100% - 30px);
  }
  #roster .addtime-popup,
  #roster .updatetime-popup {
    width: 290px;
  }
  .dropdown-toggle {
    padding: 6px 25px 6px 8px;
    font-size: 14px;
  }
  #roster table {
    margin-bottom: 70px;
  }
  .salevoucher-tab .voucher-box,
  .salevoucher-tab .membership-box {
    padding: 15px;
  }
  .full-screen-drawer .drawer-body > .row {
    height: auto;
  }
  .addstaff-member.full-screen-drawer .drawer-body {
    height: calc(100vh - 150px);
  }
  .addstaff-member .working-hrs {
    border-left: 0;
    border-top: 1px solid #eee;
  }
  .addstaff-member .service li {
    padding: 15px 0;
  }
  .checkbox label {
    font-size: 14px;
  }
  .checkbox label:before {
    width: 15px;
    height: 15px;
  }
  .checkbox label {
    padding-left: 23px;
  }
  .add-form form > .row {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .drawer-header .btn {
    min-width: auto;
    padding: 5px 8px;
    font-size: 14px;
  }
  .drawer-header .btn-cancel {
    padding: 5px 8px !important;
  }
  .service .add-new-btn.btn {
    font-size: 13px;
    padding: 5px 7px;
  }
  .services-table {
    overflow: scroll;
  }
  .table-shadow {
    margin: 16px 0;
  }
  .setting-dropdown .dropdown-toggle {
    height: 26px;
    width: 26px;
    line-height: 26px;
    font-size: 14px;
  }
  .user-upload-img img {
    max-width: 100%;
  }
  #addproduct-drawer .input-file,
  #addsuppliers-drawer .input-file,
  #editproduct-drawer .input-file,
  #editsuppliers-drawer .input-file {
    width: 130px;
    height: 120px;
  }
  .product-img img {
    max-width: 100px;
  }
  #categories .categorie-table,
  .service.list-page table {
    width: 100%;
  }
  .box-image-cover .image-content i {
    font-size: 12px;
  }
  .complete-box h4 {
    font-size: 14px;
  }
  .service.list-page .add-new-btn {
    min-width: auto;
    position: relative;
    padding: 5px 15px 5px 30px;
    font-size: 14px;
  }
  .service.list-page .add-new-btn:before {
    left: 10px;
  }
  table .pro-img {
    max-width: 70px;
  }
  table .pro-title {
    width: calc(100% - 70px);
  }
  .list-view-content {
    padding: 20px 12px;
  }
  .client-detaildrawer,
  .client-screen-two .list-block .btn-wrap {
    width: 100%;
  }
  .client-detaildrawer .close-drawer {
    right: 20px;
    top: 20px;
  }
  .client-detaildrawer .left-menu {
    border-right: 0;
    padding: 20px;
    border-bottom: 1px solid #d4d4d4;
  }
  .client-detaildrawer .user-initial {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  .client-detaildrawer .nav-pills .nav-link {
    padding: 7px 10px;
    font-size: 14px;
  }
  .client-detaildrawer .content {
    padding: 20px;
  }
  .action-box a img {
    max-width: 16px;
  }
  .client-editappoinment#addappoinment-drawer .drawer-body {
    height: calc(100vh - 285px);
  }
  .client-editappoinment .btn-lg {
    font-size: 13px;
    padding: 10px 10px;
  }
  .client-detaildrawer .content-wrp {
    height: auto;
  }
  #listview .table-responsive table {
    min-width: 490px;
  }
  .client-screen-3 .btn.btn-outline,
  .client-screen-two .btn.btn-outline {
    font-size: 13px;
    padding: 6px 8px;
  }
  .client-screen-3 .list-view-tab li a img {
    max-width: 22px;
  }
  .client-screen-two .drawer {
    width: 320px;
  }
  .client-screen-two .list-block .search {
    width: 100%;
    margin: 0 0 10px;
  }
  .client-screen-two > .row,
  .client-screen-two .client-detaildrawer > .row,
  .client-screen-two .client-detaildrawer,
  .client-screen-two .user-list,
  .client-detaildrawer > .drawer-wrp,
  .client-detaildrawer > .drawer-wrp > .drawer-body {
    height: auto;
  }
  .client-detaildrawer .drawer {
    position: fixed;
    width: 320px;
  }
  .client-drawer#addclient-drawer .insert-photo img {
    max-width: 80px;
  }
  .client-drawer#addclient-drawer .insert-photo {
    width: 190px;
    height: 190px;
  }
  .calendar-header .dropdown-toggle {
    font-size: 14px;
    padding: 8px 13px;
    line-height: 1.3;
  }
  .calendar-header .dropdown-toggle i {
    font-size: 12px;
  }
  .print-img {
    padding: 8px 10px;
  }
  .print-img img {
    max-width: 16px;
  }
  .calendar-header .custom-tab .list-group-item {
    padding: 6px 13px;
  }
  .date input,
  .date .day {
    font-size: 14px;
  }
  .service .search-wrp {
    border-top: 1px solid #eee;
  }
  #product table {
    width: 700px;
  }
  .signup-page .signup-header {
    padding: 15px;
  }
  .signup-logo img {
    max-width: 110px;
  }
  .signup-page .signup-header-right .already-login {
    width: 130px;
  }
  .signup-page .signup-header-right .already-login,
  .signup-page .signup-header-right .already-login a {
    font-size: 11px;
    line-height: normal;
    text-align: right;
  }
  .signup-page .close-btn img {
    vertical-align: top;
    max-width: 17px;
  }
  .signup-page .close-btn {
    vertical-align: top;
    margin: 5px 0 0 3px;
  }
  .signup-page .signup-content {
    padding: 90px 0 40px;
  }
  .signup-page h1,
  .preview-offer h1,
  .preview-offer .offer-box h1 {
    font-size: 40px;
  }
  .signup-page p {
    font-size: 14px;
  }
  .signup-page ul li {
    font-size: 14px;
    background-size: 16px;
    padding-left: 30px;
  }
  .signup-form.opening-hours .hours-box {
    padding: 15px 22px;
  }
  .signup-form.opening-hours .form-check {
    margin: 0 0 10px;
  }
  .signup-form.opening-hours .btn {
    min-width: auto;
    width: 100%;
  }
  .ac-setup .acsetup-left {
    width: 100%;
    height: auto;
    display: block;
    padding: 15px 7px;
    text-align: center;
  }
  .ac-setup .acsetup-left .nav-link {
    display: inline-block;
    width: auto;
    padding: 0px 10px;
    font-size: 14px;
  }
  .ac-setup .acsetup-right {
    width: 100%;
    height: auto;
  }
  .ac-setup .acsetup-right .alltab-box {
    padding: 23px;
  }
  .ac-setup .alltab-box .box-image-cover {
    min-height: 140px;
  }
  .ac-setup .acsetup-right .tab-content .tab-pane,
  .ac-setup .acsetup-right #v-pills-ClosedDates .left-content,
  .ac-setup .acsetup-right #v-pills-ClosedDates .right-content {
    padding: 17px;
  }
  .ac-setup .input-field:not(:last-child) {
    padding: 0 0 10px;
    margin: 0 0 20px;
    /* margin: 0 0 23px; */
  }
  .newsalecreditpay .form-control.lg {
    padding: 15px;
    font-size: 16px;
  }
  .newsalecreditpay .btn {
    padding: 15px;
    font-size: 22px;
  }
  .newsale-payment-btn .btn {
    padding: 15px 5px;
    font-size: 15px;
  }
  .form-control.cvc {
    background-position: 89% center;
    background-size: 35px;
  }
  .form-control.creditcard {
    background-size: 37px, 30px;
    background-position: 81% center, 96% center;
  }
  .ac-setup {
    align-content: flex-start;
  }
  .dashboard {
    padding: 30px 15px;
  }
  .dashboard .box-col {
    width: 50%;
  }
  .page-content.dashboard-content {
    padding-top: 115px;
  }
  .editconsulationform-drawer .right-content,
  .editconsulationform-drawer .right-content .form-body .drawer-form-box,
  .editconsulationform-drawer .left-content,
  .reports-content .report-box {
    padding: 15px;
  }
  .border-left-right::before,
  .border-left-right::after {
    display: none;
  }
  .preview-offer .offer-box p {
    font-size: 25px;
  }

  .create-campaigns-drawer .box-image-cover {
    margin: 15px 15px 0;
    height: 100%;
    padding: 50px 30px;
    height: initial;
  }
  .create-campaigns-drawer .drawer-body {
    margin: 0 20px;
    height: calc(100vh - 100px);
  }

  #photos-tab #photolist .img-wrap img {
    width: 100%;
    max-width: 150px;
    height: 150px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 992px) {
  .report-box.border-bottom.border-lg-0 {
    border-bottom: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  h4,
  .h4 {
    font-size: 18px;
  }
  h5,
  .h5 {
    font-size: 16px;
  }
  h6,
  .h6 {
    font-size: 14px;
  }
  .sales-list .custom-tab,
  .sales-list form,
  .preview-offer .offer-details,
  .preview-offer p.offer-subtitle-txt,
  .preview-offer .offer-box,
  .page-marketing .box-image-cover.box-marketing {
    width: 100%;
  }
  .sales-list .sales-list-header .form-control {
    font-size: 12px;
  }
  .common-tab .nav {
    justify-content: space-evenly;
  }

  .calendar-header .staff-dropdown {
    width: 100%;
    display: block;
  }
  .signup-page h1 {
    font-size: 36px;
  }
  .page-title {
    font-size: 17px;
  }
  .birthdayoffer-drawer .left-content .input-field {
    padding: 30px 30px;
  }
  .preview-offer .offer-box {
    padding: 30px 20px;
  }
}

.react-datalist-input__listbox {
  height: 166px;
  overflow: auto;
}

.list-unstyled.p-2.calendar_event_content.cursor-pointer {
  overflow: auto;
  height: 100%;
}

.header-calender-week {
  background: #fafafa;
  padding: 10px 15px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.header-calender-week.active {
  color: #8c1c4d;
}
.header-calender-week-date {
  font-size: 20px;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent !important;
}
.fc-now-indicator.fc-now-indicator-arrow::before {
  background-color: #ef6c00;
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: -7px;
}

.fc-now-indicator.fc-now-indicator-arrow {
  left: 44px !important;
  right: 0;
  background-color: #ef6c00;
  height: 2px;
  margin: 0;
  border: none;
}

.fc-timegrid-axis,
.fc-theme-standard th,
.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-media-screen .fc-timegrid-now-indicator-container {
  position: unset;
}

.fc .fc-timegrid-now-indicator-arrow {
  border-color: #ff9f24 !important;
  z-index: 1 !important;
}

.fc .fc-timegrid-now-indicator-line {
  border-bottom-width: 1px !important;
  border-top-width: 1px !important;
  border-color: #ff9f24 !important;
  background-color: #ff9f24 !important;
}

/* Day view */
.fc-resourceTimeGridDay-view .fc-timegrid-now-indicator-arrow {
  position: absolute;
  border: none;
  right: -6px;
  width: 12px;
  height: 12px;
  background-color: #ff9f24 !important;
  border-radius: 50%;
}

/* Week view */
.fc-timeGridWeek-view .fc-timegrid-now-indicator-arrow {
  display: none;
}

.fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  background-color: #ff9f24;
  border-radius: 50%;
}

.business-form.opening-hours .btn {
  min-width: 175px;
}

.business-form.opening-hours {
  padding: 30px 0;
}

.business-form.opening-hours .form-body {
  border-top: 1px solid #eee;
  margin: 25px 0;
}

.business-form.opening-hours .form-switch span {
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  padding-left: 25px;
}

.business-form.opening-hours .hours-box {
  border-bottom: 1px solid #eee;
  padding: 15px 40px;
}

.business-form.opening-hours .form-control {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 8px 15px;
}
.business-form.opening-hours .was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 1px solid #ef0000 !important;
  background-image: none;
}
.business-form.opening-hours .form-check {
  margin: 0;
}
.business-form table tr td {
  padding: 15px 8px 15px 8px;
}
#photos-tab #photolist .img-wrap img {
  width: 100%;
  max-width: 200px;
  height: 200px;
  object-fit: cover;
}
.page-appointment-campaign .container {
  padding-bottom: 100px;
}

.selecttype_drawer .marketing-inner {
  height: 100vh;
  overflow-x: hidden;
}
.selecttype_drawer .send-col a {
  text-decoration: none;
  color: #374151;
}
.selecttype_drawer .email-box-wrapper {
  width: 100%;
  max-width: 767px;
  margin: 197px auto 0 auto;
}
.selecttype_drawer .icon-cover {
  margin: 0 0 46px;
}

.selecttype_drawer .send-col h4 {
  margin: 0 0 6px;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
}

.selecttype_drawer .send-col {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  max-width: 350px;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selecttype_drawer h4.pad-text {
  padding-right: 16px;
}

/* marketin new template css  */
/* marketing sms start */
.marketing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 27px 23px 27px 30px; */
  border-bottom: 1px solid #eee;
}
.btn-c {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 700;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  background-color: #f4eeeb;
  line-height: 20px;
  color: #3a3432;
  padding: 10px 38px;
  border-radius: 2px;
  transition: all 0.4s;
  cursor: pointer;
}
.btn-c:hover {
  background-color: #8c1c4d;
  color: #fff;
}
.marketing-title {
  padding-left: 30px;
}

.marketing-right {
  padding: 27px 23px 27px 45px;
  width: calc(100% + 2px);
  background: #f9f6f4;
  display: flex;
  margin-left: -3px;
  justify-content: space-between;
  align-items: center;
}
.bg-cream {
  background-color: #f9f6f4;
}
.marketing-btn .btn {
  min-width: 114px;
}

.small-opaci {
  font-size: 16px;
  color: #999;
  line-height: 1.2;
}

.rowm-bottom {
  margin-bottom: 28px;
}

.chekbtn {
  margin-right: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.marketing-data {
  max-width: 100%;
}

.marketin-mobi-text {
  left: 56px;
  min-height: 200px;
  right: 50px;
}

.marketing-data .mobile-img {
  min-height: 800px;
}

.marketing-data .marketin-mobi-text {
  top: 152px;
}
/* marketing sms End */
@media (max-width: 1199px) {
  .marketing-right .preview {
    display: none;
  }
  .pre-show {
    display: block !important;
  }
  .marketing-right {
    background-color: transparent;
  }
  .marketin-mobi-text {
    left: 81px;
    min-height: 200px;
    right: 69px;
  }
}

@media (max-width: 991px) {
  .marketing-btn {
    display: flex;
  }

  .marketing-right {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .marketing-title {
    padding-top: 27px;
  }
}

@media (max-width: 575px) {
  .marketing-title {
    padding-top: 27px;
  }
  .marketin-mobi-text {
    left: 26px;
    min-height: 200px;
    right: 20px;
  }
}
