/* academy-box page css start  */
.academy-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    border-radius: 6px;
    min-height: 330px;
    height: 100%;
    max-width: 340px;
}
.academy-img{ min-height: 142px;}
.academy-content {
    padding: 44px 23px 26px;
    min-height: 183px;
}
.acadmy-icon {
    margin: 53px 15px -17px;
}
.academy-content h3 {
    margin: 0 0 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #8C1C4D;
}
/* .acadmy-icon img {
    max-width: 212px;
} */

/* academy-box page css End*/
/* Memberships page start  */


/*/////////////////////////////// */
.infinity-scroll-for-everyday{
    overflow: unset;
}
.marketing-titlle h4 {
    color: #333333;
    font-weight: 600;
    font-size: 22px;
}

.marketing-box-wrapper {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px;
}
.marketing-box-cover{ text-decoration: none; color: #374151;
    background-color: #fff;
    padding: 23px 20px;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: calc(20% - 40px);
    margin: 0 20px;
    margin-bottom: 48px;
    /* min-height: 250px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.marketing-box-cover h6 {
    font-size: 20px;
    margin-bottom: 8px;
}


.bg-pink-opi{ background-color: #E5E5E5;}



.Memberships-right img {
    max-width: 212px;
}
.Memberships-left h2{
    margin: 60px 0px 14px 0px;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: #8C1C4D;
}
.Memberships-left p{
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 63px;
    color: #333333;
}
.Memberships-right {
    margin-bottom: -89px;
}
.mebership-row-wrapper{  
    padding-left: 253px;
    margin-top: 52px;
}
.marketing-row-wr .row .col-md-4 a,.mebership-row-wrapper .row .col-md-4 a{ text-decoration: none; width: 100%; height: 100%;}



/* Memberships page End  */

/*everyday market css*/
.marketing-titlle h4 {
    color: #333333;
    font-weight: 600;

}

.marketing-box-wrapper {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px;
}
.marketing-box-cover{ text-decoration: none; color: #374151;
    background-color: #fff;
    padding: 23px 20px;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: calc(20% - 40px);
    margin: 0 20px;
    margin-bottom: 48px;
    /* min-height: 250px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.marketing-box-cover h6 {
    font-size: 20px;
    margin-bottom: 8px;
}

.marketing-img {
    margin-bottom: 20px;
}

.marketing-img img {
    max-height: 73px;
    min-height: 58px;
    max-width: 100%;
    object-fit: cover;
}
div#v-pills-Everydaymarketing {
    padding: 0;
    margin: 0;
    width: 100%;
    background: #f9f6f4;
    height: 100%;
    overflow-y: auto;
}
/*modal starts here*/
.main-acad-modal-containers{
    position: relative;
}
.modal-acdemy-close {
    margin: 0 auto;
    max-width: 940px;
    width: 100%;

}
div#modal-video-academys {
    background: #fff;
}
.modal-background-color {
    opacity: 0 !important;
}
.academy-video-controller video{
    margin-bottom: 44px;
    max-width: 900px;
    width: 100%;
    max-height: 603px;
}
.aca-para-content-text p{
    text-align: justify;
    font-weight: 400;
    font-size: 20px;
}
.close-modal-icon-btn {
    position: absolute;
    top: 31px;
    right: 36px;
}
.aca-model-heading h5{
    margin: 55px 0px 44px 0px;
    font-weight: 600;
    font-size: 36px;
    color: #8C1C4D;
}
/*modal ends here*/
/* upgrade-account-dialogue-modal */
.acdemy-modal-marketing{

    max-width: 1177px;
    max-height: 605px;
    background: #8f8c8b;
    padding-top: 0%;
   }
   .modal-header-container h5{
        font-weight: 600;
        font-size: 30px;
   }
   .modal-header-container p{
        font-weight: 400;
        font-size: 16px;
   }
   .modal-upgrade-container {
    max-height: 605px;
    max-width: 1177px;
}
.modal-upgrade-ac-wrapper{

    background: #fff;
    border-radius: 10px;
    padding: 66px 30px 88px 71px;
    position: relative;
}
.img-close-ac-modal{
    position: absolute;
    top: 30px;
    right: 30px;
}

.modal-upgrade-premium-header{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8f8c8b;
    height: inherit;
    padding: 0px 8px;
}
.upgrade-ac-modal-information{
    padding-left: 0px;
}
.upgrade-ac-modal-information li{
    list-style-type: none;
    font-weight: 400;
    font-size: 16px;
}

.img-everyday-market-modal img{
    object-fit: contain;
    max-width: 440px;
    width: 100%;
}
.upgrade-ac-modal-information li::marker {
        content:none;
}
.btn-upgrade-ac{
    /* padding: 16px 8px unset 8px; */
    outline: none;
    background-color: #8c1c4d;
    color: #fff;
    font-size: 19px;
    font-weight: 900;
    border: none;
    border-radius: 2px;
    max-width: 172px;
    /* height: 49px; */
}
.everyday-banner-page-wrapper h2{
    margin: 27px 0px 28px 31px ;
}
.everyday-banner-page-wrapper button{
    margin: 22px 23px 23px 0px ;
}
.main-container-market-banner{
    display: flex !important;
    align-items: center;
    justify-content: center;
   
    height: 210px;
    background: #F2F3F5;
    margin-right: 0px;
    margin-top: 47px;
    border-radius: 10px;
    max-width: 281px;
    width: 281px;
}
.slick-slide {
    margin-right: 29px;
}

.container-everyday-market-banners{
    padding-left: 74px;
    padding-right: 74px;
}

.slick-prev {
    left: -46px !important;
}
.slick-prev:before, .slick-next:before{

    color:#d9d9d9 !important;
    font-size: 40px !important;
}
.slick-track {
    height: 305px;
    
}

/* .image-slider img {
    max-width: 250px !important;
    width: 250px;
    max-height: auto !important;
    height: auto !important;
    object-fit: cover !important;
  } */
.image-slider-width-sets{
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-slider-width-sets img{ 
    width: 100% !important;
    object-fit: cover;
    max-width: 100% !important;
}
.slick-slider .slick-track, .slick-slider .slick-list {
    height: 307px;
}
.aca-banner-desc{
    font-weight: 600 !important;
    font-size: 16px !important;
    margin: 20px 0px 50px 0px;
}
.marketing-layout-slid {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
}
.hr-subeveryday-target{
    margin: 0px;
}
/*Banner page*/
/*everyday market page*/
.marketing-titlle h4 {
    color: #333333;
    font-weight: 600;

}

.marketing-box-wrapper {
    display: flex;
    flex-flow: wrap;
    margin: 0 -20px;
}
.marketing-box-cover{ text-decoration: none; color: #374151;
    background-color: #fff;
    padding: 23px 20px;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: calc(20% - 40px);
    margin: 0 20px;
    margin-bottom: 48px;
    /* min-height: 250px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.marketing-box-cover h6 {
    font-size: 20px;
    margin-bottom: 8px;
}

.marketing-img {
    margin-bottom: 20px;
}

.marketing-img img {
    max-height: 73px;
    min-height: 58px;
    object-fit: cover;
}

.academy-top-row {
    padding-left: 96px;
}

/*everyday market page*/

@media only screen and (max-width: 1399px) {
    .mebership-row-wrapper {
        padding-left: 181px;
    }
    .academy-top-row{ padding-left: 150px;}
    .Memberships-right {
        margin-bottom: -101px;
    }
}
@media only screen and (max-width: 1199px){
    .marketing-box-cover {
        width: calc(25% - 40px);
    }
    
 }
@media only screen and (max-width: 1024px) {
.mebership-row-wrapper {
    padding-left: 74px;
}
.academy-top-row {
    padding-left: 96px;
}
}

 
 


@media only screen and (max-width: 991px) {
    .academy-content h3 {
        font-size: 20px;
        line-height: 27px;
    }
    .academy-content {
        padding: 34px 15px 24px;
    }  
    .academy-top-row {
        padding-left: 20px;
        padding-right: 20px;
    }

    .mebership-row-wrapper{ padding-left: 0;}

    .marketing-box-cover {
        width: calc(100% / 2 - 48px);
    }
    .custom-sms-use-padding{
        padding: 32px !important;
    }

}

@media only screen and (max-width: 767px) {
    .Memberships-right,.Memberships-left{ text-align: center;}
    .fz-36 {
        font-size: 34px;
        line-height: 43px;
    }

    .marketing-box-cover {
        width: calc(100% / 2 - 12px);
        margin: 0 6px;
        margin-bottom: 6px;
    }

    .marketing-box-cover {
        width: calc(100% / 2 - 12px);
        margin: 0 6px;
        margin-bottom: 6px;
    }
    .marketing-box-wrapper{   margin: 0 -6px;}
    .modal-upgrade-ac-wrapper {
        padding: 66px 32px 88px 32px;
        border-radius: 0px;
    }
    .modal-upgrade-premium-header {
        align-items: baseline;
        padding: 0px;
    }
    .Memberships-right {
        margin-bottom: 0px;
    }
}
/* everyday market*/
.evryday-text-title h5{
    font-weight: 600;
    font-size: 22px;
    color: #333333;
}

/* .everyday-box-wrapper{
    
    max-height: 250px;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
   
} */
.everyday-image-wrapeer-fluid img{
    margin-top: 48px ;
    margin-bottom: 23px;
}
.everyday-image-titles h5{
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}
.everyday-image-description p{
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-bottom: 23px;
}
#everyday-marketing-images img{
    max-width: 70px;
    /* max-height: 70px; */
    object-fit: cover;
}
.html-truncate-with-parser{
    display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.upgrade-modal-image-wh-set{
    max-width: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
}
.academy-image-max-height{
    max-height: 208px;
    object-fit: cover;
}
.box-tab-aca-style{
    background: #f9f6f4;
    min-height: 100vh;
}


