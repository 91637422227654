/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Custom Sweetalert2 Styles */
.custom-popup-class {
  border-radius: 15px;
  padding: 2em;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.custom-title-class {
  color: #bc5776;; /* Your brand primary color */
  font-family: 'Your Brand Font', sans-serif;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.custom-confirm-button {
  padding: 12px 30px !important;
  font-size: 1.1em !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.custom-confirm-button:focus {
  box-shadow: 0 0 0 3px rgba(255,107,107,0.3) !important; /* Your brand primary color with opacity */
}
