.send-col {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  max-width: 350px;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-c {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
  background-color: #f4eeeb;
  line-height: 20px;
  color: #3a3432;
  padding: 10px 38px;
  border-radius: 2px;
}

.icon-cover {
  margin: 0 0 46px;
}
.send-col a {
  text-decoration: none;
  color: #374151;
  font-size: 16px;
  line-height: 20px;
}
.email-box-wrapper {
  width: 100%;
  max-width: 767px;
  margin: 197px auto 0 auto;
}

.send-col h4 {
  margin: 0 0 6px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
}

h4.pad-text {
  padding-right: 16px;
}
/* select page css End ***************/

/* brithday page css start***************/

.cir-mt {
  margin: 163px 0 0 0;
}
/* brithday page css End***************/

/* birthday-campaign page start***************/
.post-innerrow {
  width: 100%;
  max-width: 930px;
  margin: 0 auto;
}

.btn-row {
  padding-top: 31px;
  padding-bottom: 21px;
}

.post-items-wr {
  padding: 19px 21px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  align-items: center;
  margin-bottom: 21px;
}

.edi-btn {
  margin-right: 15px;
}

.edi-btn,
.del-btn {
  background-color: #8f807d;
  width: 36px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-del a {
  font-size: 12px;
  text-decoration: none;
  color: #fff;
  border-radius: 4px;
  text-align: center;
}

.del-btn a img {
  margin-bottom: 2px;
}

/* birthday-campaign page End***************/

/* active-accoun-sec page start  */
.active-accoun-sec.bg-pink {
  padding: 60px 0 103px;
  position: relative;
  overflow-x: hidden;
  z-index: 1;
}
.active-accoun-sec.bg-pink::before {
  content: "";
  position: absolute;
  /* background-image: url(../images/Frame2.png); */
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  width: 701px;
  height: 981px;
  top: 0;
  z-index: -1;
}
.active-account-title {
  max-width: 700px;
  margin: 0 auto 27px;
}
.active-account-title h2 {
  font-weight: 600;
  margin: 0 0 5px;
}
.active-account-title p{
  font-weight: 400;
  font-size: 17px;
  color: #3A3432;
}
.plan-sele {
  display: flex;
  align-items: center;
  justify-content: center;
}
.plan-lable label {
  margin: 0;
}
.plan-lable {
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #3a3432;
  margin-right: 30px;
}
.plan-selec-wr select {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 25px;
  max-width: 309px;
  background: #ffffff;
  border: 1px solid #f4eeeb;
  border-radius: 5px;
  min-width: 309px;
}
.plan-wr {
  background: #ffffff;
  border: 3px solid rgba(251, 210, 195, 0.2);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 6px;
  height: 100%;
  padding: 33px 40px 40px;
}
.plan-column-wr {
  max-width: 1024px;
  margin: 0 auto;
}
.pal-spr {
  display: flex;
  align-items: end;
  margin-bottom: 31px;
}
.pal-spr h2 {
  margin: 0;
}
.paln-list-wr ul {
  margin: 0;
  padding: 0;
}
.plan-btn-wr {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
}
.plan-btn-wr .btn:last-child {
  margin-top: 18px;
}

.plan-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 206px;
}
.pal-spr sub {
  margin: 0 3px 23px;
  font-size: 16px;
  font-weight: 600;
}
.pal-spr h2 {
  font-size: 46px;
  line-height: 57px;
  font-weight: 600;
}
.paln-list-wr h5 {
  font-size: 16px;
  margin: 0 0 3px;
  font-weight: 700;
}
.feat-link {
  margin-top: 31px;
}
.paln-chek li {
  position: relative;
  list-style: none;
  padding-left: 18px;
}
.paln-chek li::after {
  content: "";
  /* background: url(../images/chek-icon-blck.svg); */
  background-repeat: no-repeat;
  background-size: 10px;
  width: 14px;
  height: 15px;
  left: 0;
  position: absolute;
  top: 63%;
  transform: translateY(-50%);
}
.plan-wr h2 {
  font-weight: 600;
}
.plan-column-wr .col-md-4 {
  margin-top: 50px;
}
.package-links-tags{
  text-decoration: underline;
  color: #8c1c4d;
  margin-top: 31px;
}
.price-dropdown-title{
  font-weight: 700;
  font-size: 17px;
  color: #3a3432;
}
.icon-custom-color{
  color: #8c1c4d;
}
/* or 129% */

/* active-accoun-sec page End  */
/*sub package payments css*/
div#package-selects {
  overflow-y: auto;
}

.package-image-position-basic{
  position: relative;
  z-index: 11;
  min-height: 100vh;
}
.package-image-displays-positions{
  position: absolute;
  top: 102px;
  right: -164px;
  z-index: -1;
}
.package-box-content {
max-width: 548px;
width: 100%;
margin: 0 auto;
background: #fff;
border-radius: 6px;
margin-top: 53px;
padding: 38px 43px 48px 44px;
}
.page-package-desc {
max-width: 654px;
width: 100%;
margin: 0 auto;
text-align: center;
}
.page-package-desc p {
font-weight: 400;
font-size: 17px;
margin: 0px 0px;
}
.page-package-desc h5 {
font-weight: 600;
font-size: 30px;
padding-top: 60px;
margin-bottom: 0px;
}
.plane-selects-descs {
font-weight: 400;
font-size: 17px;
color: #3a3432;
margin: 0px 0px;
}
.package-box-titles {
font-weight: 600;
font-size: 32px;
color: #333333;
margin-bottom: 0px;
}
.package-box-desc {
font-weight: 600;
font-size: 46px;
margin-bottom: 0px;
}
.package-amount-pay {
font-weight: 600;
font-size: 16px;
}

.pay-desc-package {
margin: 21px 0px 19px 0px;
font-weight: 600;
font-size: 24px;
}
.plane-selection-head-wrapper{
background: #f9f6f4;
}
.pay-btn-packages-selected-plan {
font-weight: 600;
font-size: 1.2rem;
line-height: 20px;
color: #fff;
background: #8c1c4d;
display: inline-block;
border-radius: 5px;
border: 1px solid #8c1c4d;
padding: 16px 12px;
transition: all 0.4s;
}
.pay-btn-packages-selected-plan:hover {
color: #3a3432;
background: #f9f6f4;
}

/*sub package payments css*/

@media only screen and (max-width: 1440px) {
  .active-accoun-sec.bg-pink::before {
    width: 359px;
    height: 481px;
    top: 135px;
  }
}
@media only screen and (max-width: 1280px) {
  .active-accoun-sec.bg-pink::before {
    width: 320px;
    height: 451px;
    top: 0;
  }
}
@media only screen and (max-width: 991px) {
  .plan-wr {
    padding: 25px;
  }
  .active-accoun-sec.bg-pink::before {
    content: "";
    display: none;
  }
  .package-image-displays-positions img{
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .plan-wr {  
    max-width: 348px;
    margin: 0 auto;
  }

  .send-col {
    max-width: 100%;
  }

  .email-box-wrapper {
    margin: 119px auto 0 auto;
  }
  .post-items-left {
    padding-bottom: 10px;
  }

  .pal-spr h2 {
    font-size: 30px;
  }
  .pal-spr {
    margin-bottom: 25px;
  }
  .plan-lable {
    margin: 0 0 10px 0;
  }
  .plan-sele {
    flex-direction: column;
  }
  .plan-column-wr .col-md-4 {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 567px) {
  .pay-btn-packages-selected-plan {
    font-size: 16px;
    padding: 21px 2px;
  }

}